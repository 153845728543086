import React from 'react'
import PropTypes from 'prop-types' // Import PropTypes
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { USER_TYPES } from '@common/src/constants/constants'

const PrivateRoute = ({ allowedRoles, children }) => {
  const { user, permissions, loading } = useSelector((state) => state.auth)

  // Show a loading spinner or message while auth data is being fetched
  if (loading) {
    return <div>Loading...</div> // Adjust this to your preferred loading component
  }

  if (!user || !permissions || !user?.userType) {
    return <div>Loading...</div> // Additional fallback while ensuring data is loaded
  }

  // Ensure that no auth checks are done until `currentUser` and related data are fully loaded
  if (!user) {
    return <Navigate to="/login" /> // Redirect to login page if not logged in
  }

  // Check if the user is an admin (based on your `type` variable)
  if (user?.userType === USER_TYPES.HUB_ADMIN) {
    return children // If the user is an admin, allow access
  }

  // Check if the user has the required permission by checking their roles
  if (permissions && !allowedRoles.some((role) => permissions.includes(role))) {
    // return <Navigate to="/unauthorized" /> // Redirect to unauthorized if the role does not match
  }

  return children // If all checks pass, render the protected content
}

// Add PropTypes validation
PrivateRoute.propTypes = {
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired, // Validate allowedRoles as an array of strings
  children: PropTypes.node.isRequired, // Validate children prop
}

export default PrivateRoute

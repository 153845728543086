import { Formik, Form, Field, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import SelectBox from '@common/src/common/selectbox/selectbox';
import * as Yup from 'yup';
import AddProductButton from '../../../components/common/buttons/AddProductButton';
import PropTypes from 'prop-types';
import Toggle from '@common/src/common/Toggle/Toggle';
import { useState } from 'react';
import { PRODUCT_ADD } from '@hub/constants/constants';

const daysOptions = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
  { value: 'Sunday', label: 'Sunday' },
];
const dayAbbreviations = {
  Monday: 'MON',
  Tuesday: 'TUE',
  Wednesday: 'WED',
  Thursday: 'THU',
  Friday: 'FRI',
  Saturday: 'SAT',
  Sunday: 'SUN',
};

const validationSchema = Yup.object().shape({
  dateStart: Yup.date().required('Start date is required'),
  dateEnd: Yup.date().required('End date is required'),
  timeStart: Yup.string().required('Start time is required'),
  timeEnd: Yup.string().required('End time is required'),
  days: Yup.array().min(1, 'At least one day is required'),
});

const Availability = ({ handleNext, setProductData, productData }) => {
  const [timed, setTimed] = useState(false);

  const handleSubmit = (values) => {
    // Determine the day option
    let dayOption;
    if (values?.daysAll) {
      dayOption = PRODUCT_ADD.ALL;
    } else if (values.days.length === 7) {
      dayOption = PRODUCT_ADD.ALL;
    } else {
      dayOption = PRODUCT_ADD.SOME;
    }

    // Convert selected days to abbreviations
    const selectedDays = values.days.map(day => dayAbbreviations[day]);
  
    // Construct the availability object
    const availability = {
      date: {
        start: values.dateStart,
        end: values.dateEnd,
        option: values.dateAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
      },
      day: {
        days: selectedDays ,
        option: dayOption,
      },
      time: {
        start: values.timeStart,
        end: values.timeEnd,
        option: values.timeAll ? PRODUCT_ADD.ALL : PRODUCT_ADD.RANGE,
      },
    };
  
    // Determine if the product is available today
    const today = new Date();
    const availableToday = values.dateStart <= today && values.dateEnd >= today;
  
    console.log("availability", availability);
  
    // Update the product data and proceed to the next step
    setProductData({ ...productData, availability, availableToday });
    handleNext();
  };

  return (
    <div className="grid grid-cols-12 gap-6 ">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                initialValues={{
                  dateStart: null,
                  dateEnd: null,
                  days: [],
                  timeStart: null,
                  timeEnd: null,
                  timed: false,
                  dateAll: false,
                  daysAll: false,
                  timeAll: false,
                }}
               // validationSchema={timed && validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form>
                    {/* Timed toggle */}
                    <div className="xl:col-span-4 col-span-12">
                      <Toggle
                        label="Timed"
                        name="timed"
                        checked={timed}
                        onChange={(e) => {
                          setFieldValue("timed", e.target.checked);
                          setTimed(e.target.checked);
                        }}
                      />
                    </div>

                    {timed && (
                      <>
                        {/* Date Range */}
                        <div className="xl:col-span-12 col-span-12">
                          <label className="form-label" htmlFor='date'>Date Range</label>
                          <div className="flex space-x-4">
                            <div className="w-full">
                              <DatePicker
                                selected={values.dateStart}
                                onChange={(date) => setFieldValue("dateStart", date)}
                                selectsStart
                                startDate={values.dateStart}
                                endDate={values.dateEnd}
                                placeholderText="Start Date"
                                className="form-control w-full !rounded-md"
                              />
                              <ErrorMessage name="dateStart" component="div" style={{ color: 'red' }} />
                            </div>
                            <div className="w-full">
                              <DatePicker
                                selected={values.dateEnd}
                                onChange={(date) => setFieldValue("dateEnd", date)}
                                selectsEnd
                                startDate={values.dateStart}
                                endDate={values.dateEnd}
                                minDate={values.dateStart}
                                placeholderText="End Date"
                                className="form-control w-full !rounded-md"
                              />
                              <ErrorMessage name="dateEnd" component="div" style={{ color: 'red' }} />
                            </div>
                            <button type="button" onClick={() => setFieldValue("dateAll", !values.dateAll)}>
                              {PRODUCT_ADD.ALL}
                            </button>
                          </div>
                        </div>

                        {/* Days Selection */}
                        <div className="xl:col-span-4 col-span-12">
                          <SelectBox
                            label="Select Day(s)"
                            name="days"
                            options={daysOptions}
                            isMulti={true}
                            value={daysOptions.filter(option => values.days.includes(option.value))}
                            onChange={(e) => setFieldValue("days", e.map(day => day.value))}
                            placeholder="Select Days"
                          />
                          <ErrorMessage name="days" component="div" style={{ color: 'red' }} />
                          <button type="button" onClick={() => setFieldValue("daysAll", !values.daysAll)}>
                            {PRODUCT_ADD.ALL}
                          </button>
                        </div>

                        {/* Time Range */}
                        <div className="xl:col-span-4 col-span-12">
                          <label className="form-label" htmlFor='time'>Time Range</label>
                          <div className="flex space-x-4">
                            <div className="w-full">
                              <Field type="time" name="timeStart" className="form-control w-full !rounded-md" />
                              <ErrorMessage name="timeStart" component="div" style={{ color: 'red' }} />
                            </div>
                            <div className="w-full">
                              <Field type="time" name="timeEnd" className="form-control w-full !rounded-md" />
                              <ErrorMessage name="timeEnd" component="div" style={{ color: 'red' }} />
                            </div>
                            <button type="button" onClick={() => setFieldValue("timeAll", !values.timeAll)}>
                              {PRODUCT_ADD.ALL}
                            </button>
                          </div>
                        </div>
                      </>
                    )}

                    <AddProductButton />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Availability.propTypes = {
  handleNext: PropTypes.func.isRequired,
  setProductData: PropTypes.func.isRequired,
  productData: PropTypes.array,
};

export default Availability;


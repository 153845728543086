import { useState, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { collection, query, where } from 'firebase/firestore'

import PageHeader from '@common/src/pageheader/pageheader'
import TableList from '@common/src/common/Table/TableList'
import { MAP_STATUS, MAP_USER_TYPES } from '../constants/map'
import { COLLECTIONS, ROLE, SUBADMIN_TYPES } from '../constants/constants'

import { db } from '../../../src/firebase/firebaseapi'
import FloatingCanvas from '../common/offcanvas/floatingCanvas'
import useFloatingCanvas from '../hooks/useFloatingCanvas'
import AddUser from './addusers'

const getColums = (permissions, onEditClick) => {
  return [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firtsName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (_, row) => (
        <>
          {row.roles?.map((role) => {
            return (
              <span className="badge bg-light text-default" key={role.name}>
                {role.name}
              </span>
            )
          })}
        </>
      ),
    },
    {
      title: 'User Type',
      dataIndex: 'userType',
      key: 'userType',
      render: (_, row) => <>{MAP_USER_TYPES[row.userType]}</>,
    },
    { title: 'Status', dataIndex: 'status', key: 'status', render: (_, row) => <>{MAP_STATUS[row.status]}</> },
    {
      title: 'Action',
      key: 'action',
      className: 'text-start',
      render: (_, record) => (
        <div className="flex flex-row items-center !gap-2 text-[0.9375rem]">
          <Link
            aria-label="anchor"
            to="#"
            onClick={() => onEditClick(record)}
            className="ti-btn ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"
          >
            <i className="ri-pencil-line"></i>
          </Link>
        </div>
      ),
    },
  ]
}

let ref = collection(db, COLLECTIONS.USERS)
ref = query(ref, where('userType', 'in', SUBADMIN_TYPES[ROLE.ROLE_TYPE_HUB]), where('isDeleted', '==', false))
const pagination = {
  pageSize: 20,
}

const User = () => {
  const tableRef = useRef(null)
  const [data, setData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()

  const onEditClick = (data) => {
    setData(data)
    setIsEdit(true)
    openOffCanvas('editUser', 'Edit User')
  }
  const columns = useMemo(() => getColums('permissions', onEditClick), [])
  const onComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }
  const onAddClick = () => {
    setData(null)
    setIsEdit(false)
    openOffCanvas('addUser', 'Add User')
  }
  return (
    <>
      <PageHeader currentpage="User" activepage="User Management" mainpage="users" />
      <TableList
        ref={tableRef}
        name="User List"
        columns={columns}
        dbRef={ref}
        pagination={pagination}
        tools={{
          btnName: 'Add User',
          btnIcon: 'ri-add-line',
          btnClick: onAddClick,
        }}
      />
      <FloatingCanvas
        id={'hs-test-123'}
        onClose={() => closeOffCanvas(false)}
        isVisible={isVisible}
        close={closeOffCanvas}
        title={offCanvasTitle}
        okText="Submit"
        closeText="Close"
      >
        {['addUser', 'editUser'].includes(activeComponent) && (
          <AddUser data={data} isEdit={isEdit} onComplete={onComplete} />
        )}
      </FloatingCanvas>
    </>
  )
}
export default User

import { CLOUD_FUNCTIONS } from '../../constants/constants'
import { callCloudFunction } from './cloud'

export const categoryAction = async (categoryData, isEdit = false) => {
  return callCloudFunction(isEdit ? CLOUD_FUNCTIONS.UPDATE_CATEGORY : CLOUD_FUNCTIONS.CREATE_CATEGORY, categoryData)
}
export const modifierAction = async (modifierData, isEdit = false) => {
  return callCloudFunction(isEdit ? CLOUD_FUNCTIONS.UPDATE_MODIFIER : CLOUD_FUNCTIONS.CREATE_MODIFIER, modifierData)
}
export const modifierGroupAction = async (modifierGroupData, isEdit = false) => {
  return callCloudFunction(
    isEdit ? CLOUD_FUNCTIONS.UPDATE_MODIFIER_GROUP : CLOUD_FUNCTIONS.CREATE_MODIFIER_GROUP,
    modifierGroupData
  )
}
export const companyAction = async (companyData, isEdit = false) => {
  return callCloudFunction(isEdit ? CLOUD_FUNCTIONS.UPDATE_COMPANY : CLOUD_FUNCTIONS.CREATE_COMPANY, companyData)
}

export const storeAction = async (storeData, isEdit = false) => {
  return callCloudFunction(isEdit ? CLOUD_FUNCTIONS.UPDATE_STORE : CLOUD_FUNCTIONS.CREATE_STORE, storeData)
}

export const createProduct = async (data) => {
    return await callCloudFunction(CLOUD_FUNCTIONS.CREATE_PRODUCT, data)
}

import React, { useEffect, useState } from 'react'
import { GET_MODIFIER_GROUP } from '@common/src/lib/graphql/query/store'
import { useQuery } from '@apollo/client'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import ModifierGroupAdd from './ModiferGroupAdd'
import AddProductButton from '@hub/components/common/buttons/AddProductButton'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const Modifiers = ({ setProductData, productData, handleNext }) => {
  const { selectedCompany } = useSelector((state) => state.auth);
  const [modifiersData, setModifiersData] = useState([{
    modifierGroup: '',
    quantity: '',
  }])

  const [modifierGroupData, setModifierGroupData] = useState([])
  const [tableData, setTableData] = useState(productData?.productModGroups || [])
  const [editData, setEditData] = useState(null);
  const { isVisible, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()

  const [pagination] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });

  const { data, refetch } = useQuery(GET_MODIFIER_GROUP, {
    variables: {
      companyId: selectedCompany?.value,
      limit: pagination.limit,
      offset: pagination.offset,
    },
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      setModifiersData(data.modifierGroupQuery.rows.map(item => ({
        modifierGroup: item.modGroupName?.en || '',
        quantity: '',
      })))

      setModifierGroupData(data.modifierGroupQuery.rows.map(item => ({
        value: item.id,
        label: item.modGroupName?.en || ''
      })))
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        companyId: 30,
        limit: pagination.limit,
        offset: pagination.offset,
      },
    });
  }, [pagination.offset]);


  const handleSubmit = () => {
    setProductData({
      ...productData,
      productModGroups: tableData,
      customizable: tableData.length > 0,
    });
    handleNext();
  };

  const onClose = () => {
    closeOffCanvas(false)
  }

  const handleEditModifier = (modifier) => {
    console.log('modifier', modifiersData);
    setEditData(modifier);
    openOffCanvas('editModifier', 'Edit Modifier');
  };
  
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="flex">

            <button
              type="button"
              onClick={openOffCanvas}
              className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary"
            >
              <i className={`ri-add-line font-semibold align-middle me-1`}></i>Add Modifier Group
            </button>
          </div>
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <div className="box-body">
                <div className="table-responsive mb-4">
                  <table className="table whitespace-nowrap table-bordered min-w-full">
                    <thead>
                      <tr>
                        <th scope="col" className="text-start">Modifier Group</th>
                        <th scope="col" className="text-start">Quantity</th>
                        <th scope="col" className="text-start">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((modifier, index) => (
                        <tr className="product-list" key={modifier?.modifierGroup?.id}>
                          <td>{modifier.modifierGroup.value}</td>
                          <td>{modifier.quantity}</td>
                          <td>
                            <button
                              type="button"
                              onClick={() => handleEditModifier(modifier)}
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                  <AddProductButton handleNext={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <FloatingCanvas onClose={onClose} isVisible={isVisible} close={closeOffCanvas} title={offCanvasTitle}>
        <ModifierGroupAdd options={modifierGroupData} setTableData={setTableData} tableData={tableData} data={editData} isEdit={!!editData} />
      </FloatingCanvas>
    </div>
  )
}

Modifiers.propTypes = {
  setProductData: PropTypes.func,
  productData: PropTypes.object,
  handleNext: PropTypes.func
}

export default Modifiers


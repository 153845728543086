import React from 'react'
import PropTypes from 'prop-types' 

const InputBox = ({
  id,
  label,
  placeholder,
  value,
  maxLength,
  onChange,
  errorText,
  className = '',
  labelClassName = '',
  inputClassName = '',
  ...rest
}) => {
  return (
    <div className={`input-box-container ${className}`}>
      {label && (
        <label htmlFor={id} className={`form-label mt-3 ${labelClassName}`}>
          {label}
        </label>
      )}
      <input
        type="text"
        className={`form-control w-full !rounded-md ${inputClassName}`}
        id={id}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {errorText && (
        <label htmlFor={id} className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0" style={{ color: 'red' }}>
          {errorText}
        </label>
      )}
    </div>
  )
}

InputBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string, // Add inputClassName to propTypes
}

export default InputBox

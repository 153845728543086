import loaderImg from './loader.svg'

const Loader = () => {
  return (
    <>
      <div>
        <img src={loaderImg} />
      </div>
    </>
  )
}

export default Loader

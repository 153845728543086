// User types
export const ROLE = {
  ROLE_TYPE_HUB: 'HUB',
  ROLE_TYPE_PULSE: 'PULSE',
}
export const USER_TYPES = {
  HUB_ADMIN: 'HUB_ADMIN', // super admins hub
  PULSE_ADMIN: 'PULSE_ADMIN', // super admins pulse
  PULSE_SUB_ADMIN: 'PULSE_SUB_ADMIN',
  HUB_SUB_ADMIN: 'HUB_SUB_ADMIN',
  HUB_PULSE_SUBADMIN: 'HUB_PULSE_SUBADMIN',
}
export const SUBADMIN_TYPES = {
  [ROLE.ROLE_TYPE_HUB]: [USER_TYPES.HUB_SUB_ADMIN, USER_TYPES.HUB_PULSE_SUBADMIN],
  [ROLE.ROLE_TYPE_PULSE]: [USER_TYPES.PULSE_SUB_ADMIN, USER_TYPES.HUB_PULSE_SUBADMIN],
}
export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
}
// User
export const USER = {
  DEFAULT_PASSWORD: 'Test@123',
}

// Firestore collection names
export const COLLECTIONS = {
  USERS: 'users',
  ROLES: 'roles',
  MASTER_PERMISSIONS: 'masterPermissions',
  COMPANIES: 'companies',
  COUNTRIES: 'countries',
  BANNERS: 'banners',
  PAYMENT_CONFIGS:'paymentConfigs'
}
export const QUERY = {
  ORDER_BY_DESC: 'desc',
}
export const CLOUD_FUNCTIONS = {
  CREATE_HUB_SUBADMINS: 'api-accounts-createUser',
  CREATE_CATEGORY: 'api-accounts-createCategory',
  UPDATE_CATEGORY: 'api-accounts-updateCategory',
  UPDATE_HUB_ROLE: 'api-accounts-updateRole',
  CREATE_MODIFIER: 'api-accounts-createModifier',
  UPDATE_MODIFIER: 'api-accounts-updateModifier',
  CREATE_MODIFIER_GROUP: 'api-accounts-createModifierGroup',
  UPDATE_MODIFIER_GROUP: 'api-accounts-updateModifierGroup',
  CREATE_COMPANY: 'api-accounts-createCompany',
  UPDATE_COMPANY: 'api-accounts-updateCompany',
  CREATE_STORE: 'api-store-createStore',
  UPDATE_STORE: 'api-store-updateStore',
  CREATE_PRODUCT: 'api-products-createProduct',
}
export const CALLBLE_HEADER = {
  mode: 'no-cors',
  headers: {
    'Accept-Language': 'en',
  },
}
export const COMPANY_ID = 27
export const MENU = {
  TYPE_LINK: 'link',
  TYPE_SUB: 'sub',
}

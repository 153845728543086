import React, { useState, useCallback, useMemo } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'
import LanguageSwitchTab from '../LanguageSwitchTab/LanguageSwitchTab'

const apiKey = '' // Replace with your DeepL API key
const translateText = async (text, targetLang) => {
  const url = 'https://api-free.deepl.com/v2/translate'

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        auth_key: apiKey,
        text,
        target_lang: targetLang,
      }).toString(),
    })

    const data = await response.json()
    return data.translations[0]?.text || ''
  } catch (error) {
    console.error('Error translating text:', error)
    return ''
  }
}

const defaultLang = 'en'
const languages = { en: 'EN', ar: 'AR' }

const MultiLanguageInput = ({ label, id, name, setFieldValue, errors, values, type = 'input' }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang)

  // Memoize the translate function to avoid recreation unless selectedLanguage changes
  const handleTranslateClick = useCallback(async () => {
    const typedText = values[name]?.[defaultLang] || '' // English as default source language
    if (selectedLanguage !== defaultLang && typedText) {
      const translatedText = await translateText(typedText, selectedLanguage)
      setFieldValue(`${name}.${selectedLanguage}`, translatedText)
    }
  }, [selectedLanguage, values, name, setFieldValue])

  // Memoize the rendering of error messages to prevent re-rendering
  const renderErrorMessages = useMemo(() => {
    return errors?.[name] ? (
      Object.keys(errors[name]).map((lang) => {
        return (
          <>
            <label htmlFor={id} className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0" style={{ color: 'red' }}>
              {errors[name][lang] ? errors[name][lang] : ''}
            </label>
            <br />
          </>
        )
      })
    ) : (
      <></>
    )
  }, [errors])

  return (
    <div className="col-span-12">
      <div className="flex items-center justify-between">
        <label htmlFor={id} className="form-label">
          {label}
        </label>
        <LanguageSwitchTab value={selectedLanguage} onChange={setSelectedLanguage} languages={languages} />
      </div>

      <Field
        as={type}
        className="form-control w-full rounded-md"
        id={`${name}.${selectedLanguage}`}
        name={`${name}.${selectedLanguage}`}
        placeholder={`${label} in ${languages[selectedLanguage]}`}
        value={values[name]?.[selectedLanguage] || ''}
        onChange={(e) => setFieldValue(`${name}.${selectedLanguage}`, e.target.value)}
        rows={type === 'textarea' ? 2 : undefined} // Optional rows attribute for textarea
      />

      {selectedLanguage !== defaultLang && (
        <>
          <button type="button" className="btn btn-secondary mt-2" onClick={handleTranslateClick}>
            Translate
          </button>
          <br />
        </>
      )}

      {renderErrorMessages}
    </div>
  )
}

MultiLanguageInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  type: PropTypes.string, // Default type is input, can be 'textarea' or other input types
}

export default MultiLanguageInput

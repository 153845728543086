import { useState, useRef, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { collection } from 'firebase/firestore'
import PageHeader from '@common/src/pageheader/pageheader'
import TableList from '@common/src/common/Table/TableList'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas'
import { db } from '../../firebase/firebaseapi'
import { COLLECTIONS } from '@common/src/constants/constants'
import { useSelector } from 'react-redux'
import BannerForm from './bannerForm'
import RowImage from '@hub/components/common/ImageHolders/rowImage'
const getColums = (permissions, onEditClick) => {
    return [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'desc',
        },
        {
            title: 'App',
            dataIndex: 'app',
            key: 'appBanner',
            render: (_, row) => <RowImage src={row.app} />,
        },
        {
            title: 'Web App',
            dataIndex: 'Web',
            key: 'webBanner',
            render: (_, row) => <RowImage src={row.web} />,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (_, row) => <>{row.startDate ? new Date(row.startDate.toDate()).toLocaleString() : ''}</>,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (_, row) => <>{row.endDate ? new Date(row.endDate.toDate()).toLocaleString() : ''}</>,
        },
        { title: 'Sort Order', dataIndex: 'sortOrder', key: 'sortOrder', render: (_, row) => <>{row.sortOrder}</> },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, row) => <>{row.status}</>
        },
        {
            title: 'Action',
            key: 'action',
            className: 'text-start',
            render: (_, record) => (
                <div className="flex flex-row items-center !gap-2 text-[0.9375rem]">
                    <Link
                        aria-label="anchor"
                        to="#"
                        onClick={() => onEditClick(record)}
                        className="ti-btn ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"
                    >
                        <i className="ri-pencil-line"></i>
                    </Link>

                </div>
            ),
        },
    ]
}

const pagination = {
    pageSize: 20,
}

const Banner = () => {
    const tableRef = useRef(null)
    const [data, setData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const { selectedCompany } = useSelector((state) => state.auth)
    const [dbRef, setDbRef] = useState(null)
    const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
    useEffect(() => {
        if (selectedCompany) {
            setDbRef(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${COLLECTIONS.BANNERS}`))
        }
    }, [selectedCompany])

    const onEditClick = (data) => {
        setData(data)
        setIsEdit(true)
        openOffCanvas('editBanner', 'Edit Banner')
    }
    const columns = useMemo(() => getColums('permissions', onEditClick), [])
    const onComplete = () => {
        if (tableRef.current) {
            tableRef.current.refreshCurrentPage()
            closeOffCanvas()
        }
    }
    const onAddClick = () => {
        setData(null)
        setIsEdit(false)
        openOffCanvas('addBanner', 'Add Banner')
    }

    return (
        <>
            <PageHeader currentpage="Banners" activepage="Promotions" mainpage="Banner" />
            <TableList
                name="Banners"
                columns={columns}
                dbRef={dbRef}
                pagination={pagination}
                tools={{
                    btnName: 'Add Banner',
                    btnIcon: 'ri-add-line',
                    btnClick: onAddClick,
                }}
                ref={tableRef} />
            <FloatingCanvas
                id={'hs-test-123'}
                onClose={() => closeOffCanvas(false)}
                isVisible={isVisible}
                close={closeOffCanvas}
                title={offCanvasTitle}
                okText="Submit"
                closeText="Close"
            >

                {['addBanner', 'editBanner'].includes(activeComponent) && (
                    <BannerForm data={data} isEdit={isEdit} onComplete={onComplete} />
                )}
            </FloatingCanvas>
        </>
    )
}
export default Banner


import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Renders a pagination component.
 *
 * @param {Object} pagination - The pagination object.
 * @param {function} setPagination - Function to set the pagination state.
 * @param {Array} rows - The rows data.
 *
 * @returns {React.ReactNode} The pagination component.
 */
const Pagination = ({ pagination, setPagination, rows }) => {
    const handleNext = () => {
        if (pagination.currentPage === pagination.totalPages) return
        if (rows.length < pagination.limit) {
            return;
        }
        setPagination((prevPagination) => ({
            ...prevPagination,
            offset: prevPagination.offset + prevPagination.limit,
            currentPage: prevPagination.currentPage + 1,
        }));
    };

    const handlePrevious = () => {
        if (pagination.offset <= 0) {
            return;
        }
        setPagination((prevPagination) => ({
            ...prevPagination,
            offset: prevPagination.offset - prevPagination.limit,
            currentPage: prevPagination.currentPage - 1,
        }));
    };
    return (
        <div className="sm:flex items-center justify-between flex-wrap">
            <nav aria-label="Page navigation">
                <ul className="ti-pagination mb-0 flex flex-row rounded-sm text-[1rem] !ps-0">
                    <li className="page-item disabled">
                        <Link className="page-link !py-[0.375rem] !px-[0.75rem]" to="#" onClick={handlePrevious}>
                            Previous
                        </Link>
                    </li>
                    <li className="page-item active">
                        <Link className="page-link !py-[0.375rem] !px-[0.75rem]" to="#">
                            {pagination?.currentPage}
                        </Link>
                    </li>
                    <li className="page-item"  >
                        <Link className="page-link !py-[0.375rem] !px-[0.75rem]" to="#" onClick={handleNext} >
                            Next
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
};

Pagination.propTypes = {
    rows: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
        limit: PropTypes.number.isRequired,
        offset: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired
    }).isRequired,
    setPagination: PropTypes.func.isRequired,
};

export default Pagination;


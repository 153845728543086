// User types
export const USER_TYPES = {
  HUB_ADMIN: 'HUB_ADMIN',
  HUB_SUBADMIN: 'HUB_SUBADMIN',
  HUB_PULSE_SUBADMIN: 'HUB_PULSE_SUBADMIN',
}

// Firestore collection names
export const COLLECTIONS = {
  USERS: 'users',
  ROLES: 'roles',
  COMPANIES: 'companies',
  BANNERS: 'banners',
  HOME:'home',
  STORES:'stores',
  NOTIFICATIONS: 'notifications'
}

export const PERMISSION = {
  ALL: 'all',
}

export const MENU = {
  TYPE_LINK: 'link',
  TYPE_SUB: 'sub',
}

export const ROLE = {
  ROLE_TYPE_HUB: 'HUB',
  ROLE_TYPE_PULSE: 'PULSE',
}

export const QUERY = {
  ORDER_BY_DESC: 'desc',
}

export const CATALOG = {
  PAGE_SIZE: 2,
  ID: '27',
  CATEGORY_COLLECTION: 'categories',
  MODIFIER_COLLECTION: 'modifiers',
  MODIFIER_GROUP_COLLECTION: 'modifierGroups',
  PRODUCT_COLLECTION: 'products',
  COMPANY_NOTIFICATIONS: 'companyNotifications',
  NOTIFICATION: 'notification',
}

export const CURRENCY_OPTIONS = [
  { value: 'INR', label: 'INR' },
  { value: 'AED', label: 'AED' },
  { value: 'RIY', label: 'RIY' },
]

export const STATUS_OPTIONS = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
]

export const FOOD_OPTIONS = [
  { value: 'veg', label: 'VEG' },
  { value: 'non_veg', label: 'Non-Veg' },
  { value: 'egg', label: 'Egg' },
]

export const GROUP_TYPES = [
  { value: 'variant', label: 'Variant' },
  { value: 'addon', label: 'Add-On' },
]

export const TAX_TYPE_OPTIONS = [
  { value: 'vat', label: 'VAT' },
  { value: 'gst', label: 'GST' },
]

export const SERVICE_OPTIONS = [
  {
    value: 'DELIVERY',
    label: 'Delivery',
  },
  {
    value: 'TAKEAWAY',
    label: 'Takeaway',
  },
  {
    value: 'GLOBAL_DELIVERY',
    label: 'Global Delivery',
  },
  {
    value: 'GLOBAL_TAKEAWAY',
    label: 'Global Takeaway',
  },
]

export const IMG_UPLOAD = {
  BANNER: {
    APP: {
      MIN_RESOLUTION: { WIDTH: 1024, HEIGHT: 549 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: '1 MB',
    },
    WEB: {
      MIN_RESOLUTION: { WIDTH: 1920, HEIGHT: 823 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    }
  },
  PRODUCT_IMAGE: {
    APP: {
      MIN_RESOLUTION: { WIDTH: 1024, HEIGHT: 549 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    },
    WEB: {
      MIN_RESOLUTION: { WIDTH: 1920, HEIGHT: 823 },
      FORMATS: ['jpeg', 'png', 'jpg'],
      MAX_SIZE: "1 MB"
    }
    
  },
  CHEF_PICKS: {
    MIN_RESOLUTION: { WIDTH: 1920, HEIGHT: 823 },
    FORMATS: ['jpeg', 'png', 'jpg'],
    MAX_SIZE: "1 MB"
  },
}
export const PRODUCT_ADD = {
   ALL:"ALL",
   SOME:"SOME",
   RANGE:"RANGE"
}

import { gql } from '@apollo/client';

export const GET_ALL_PRODUCTS = gql`
  query getAllProducts($search: String, $limit: Int, $offset: Int) {
    productListQuery(search: $search, limit: $limit, offset: $offset) {
      limit
      offset
      totalPages
      rows {
        id
        name {
          ar
          en
        }
        description {
          ar
          en
        }
        type
        price
        image
        company{
          name {
            ar
            en
          }
        }
      }
    }
  }
`;

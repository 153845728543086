import { db } from '../../../../src/firebase/firebaseapi'
import { collection, getDocs } from 'firebase/firestore'
import { COLLECTIONS } from '../../constants/constants'

export const getCountries = async () => {
  try {
    // Use the new modular syntax
    const countriesCollectionRef = collection(db, COLLECTIONS.COUNTRIES);
    const getCountries = await getDocs(countriesCollectionRef);

    // Process the documents
    const countriesData = getCountries.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    console.log(countriesData)
    
    return countriesData;
  } catch (error) {
    console.error('Error fetching country data:', error);
  }
}

export const getPaymentOptions = async () => {
  try {
    // Use the new modular syntax
    const paymentCollectionRef = collection(db, COLLECTIONS.PAYMENT_CONFIGS);
    const paymentConfigs = await getDocs(paymentCollectionRef);

    // Process the documents
    const paymentData = paymentConfigs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    console.log(paymentData)
    
    return paymentData;
  } catch (error) {
    console.error('Error fetching payment data:', error);
  }
}

export const getCompanies = async () => {
  try {
    // Use the new modular syntax
    const companyCollectionRef = collection(db, COLLECTIONS.COMPANIES);
    const getCompanies = await getDocs(companyCollectionRef);

    // Process the documents
    const companyData = getCompanies.docs.map((doc) => {
      const data = doc.data(); // Access the document data
      return {
        value: parseInt(doc.id) ? parseInt(doc.id) : doc.id, // Document ID
        label: data.name?.en || '', // Access the English name, with a fallback if it's missing
      };
    });

    console.log(companyData)
    
    return companyData;
  } catch (error) {
    console.error('Error fetching country data:', error);
  }
}

import { useDispatch, connect, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import CompanyDrop from './companydrop'

import { MAP_USER_TYPES } from '@common/src/constants/map'

import store from '../../../redux/store/index'
import Modalsearch from '../common/modalsearch/modalsearch'
import { ThemeChanger } from '../../../redux/modules/theme/action'
import logo1 from '@hub/assets/images/brand-logos/desktop-logo.png'
import face9 from '@hub/assets/images/faces/9.jpg'
import { auth } from '../../../firebase/firebaseapi'

const Header = ({ localVariable, ThemeChanger }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const handleLogout = async () => {
    try {
      await signOut(auth)
      console.log('User logged out successfully.')
      navigate('/') // Redirect to login page after logout
    } catch (error) {
      console.error('Error logging out: ', error)
    }
  }

  function menuClose() {
    const theme = store.getState().theme
    if (window.innerWidth <= 992) {
      dispatch(ThemeChanger({ ...theme, toggled: 'close' }))
    }
    if (window.innerWidth >= 992) {
      dispatch(ThemeChanger({ ...theme, toggled: localVariable.toggled ? localVariable.toggled : '' }))
    }
  }
  const toggleSidebar = () => {
    const theme = store.getState().theme
    let sidemenuType = theme.dataNavLayout
    if (window.innerWidth >= 992) {
      if (sidemenuType === 'vertical') {
        let verticalStyle = theme.dataVerticalStyle
        const navStyle = theme.dataNavStyle
        switch (verticalStyle) {
          // closed
          case 'closed':
            dispatch(ThemeChanger({ ...theme, dataNavStyle: '' }))
            if (theme.toggled === 'close-menu-close') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              dispatch(ThemeChanger({ ...theme, toggled: 'close-menu-close' }))
            }
            break
          // icon-overlay
          case 'overlay':
            dispatch(ThemeChanger({ ...theme, dataNavStyle: '' }))
            if (theme.toggled === 'icon-overlay-close') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              if (window.innerWidth >= 992) {
                dispatch(ThemeChanger({ ...theme, toggled: 'icon-overlay-close' }))
              }
            }
            break
          // icon-text
          case 'icontext':
            dispatch(ThemeChanger({ ...theme, dataNavStyle: '' }))
            if (theme.toggled === 'icon-text-close') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              dispatch(ThemeChanger({ ...theme, toggled: 'icon-text-close' }))
            }
            break
          // doublemenu
          case 'doublemenu':
            dispatch(ThemeChanger({ ...theme, dataNavStyle: '' }))
            if (theme.toggled === 'double-menu-open') {
              dispatch(ThemeChanger({ ...theme, toggled: 'double-menu-close' }))
            } else {
              let sidemenu = document.querySelector('.side-menu__item.active')
              if (sidemenu) {
                dispatch(ThemeChanger({ ...theme, toggled: 'double-menu-open' }))
                if (sidemenu.nextElementSibling) {
                  sidemenu.nextElementSibling.classList.add('double-menu-active')
                } else {
                  dispatch(ThemeChanger({ ...theme, toggled: 'double-menu-close' }))
                }
              }
            }

            // doublemenu(ThemeChanger);
            break
          // detached
          case 'detached':
            if (theme.toggled === 'detached-close') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              dispatch(ThemeChanger({ ...theme, toggled: 'detached-close' }))
            }
            break

          // default
          case 'default':
            dispatch(ThemeChanger({ ...theme, toggled: '' }))
        }
        switch (navStyle) {
          case 'menu-click':
            if (theme.toggled === 'menu-click-closed') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              dispatch(ThemeChanger({ ...theme, toggled: 'menu-click-closed' }))
            }
            break
          // icon-overlay
          case 'menu-hover':
            if (theme.toggled === 'menu-hover-closed') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              dispatch(ThemeChanger({ ...theme, toggled: 'menu-hover-closed' }))
            }
            break
          case 'icon-click':
            if (theme.toggled === 'icon-click-closed') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              dispatch(ThemeChanger({ ...theme, toggled: 'icon-click-closed' }))
            }
            break
          case 'icon-hover':
            if (theme.toggled === 'icon-hover-closed') {
              dispatch(ThemeChanger({ ...theme, toggled: '' }))
            } else {
              dispatch(ThemeChanger({ ...theme, toggled: 'icon-hover-closed' }))
            }
            break
        }
      }
    } else {
      if (theme.toggled === 'close') {
        dispatch(ThemeChanger({ ...theme, toggled: 'open' }))

        setTimeout(() => {
          if (theme.toggled == 'open') {
            const overlay = document.querySelector('#responsive-overlay')

            if (overlay) {
              overlay.classList.add('active')
              overlay.addEventListener('click', () => {
                const overlay = document.querySelector('#responsive-overlay')

                if (overlay) {
                  overlay.classList.remove('active')
                  menuClose()
                }
              })
            }
          }

          window.addEventListener('resize', () => {
            if (window.screen.width >= 992) {
              const overlay = document.querySelector('#responsive-overlay')

              if (overlay) {
                overlay.classList.remove('active')
              }
            }
          })
        }, 100)
      } else {
        dispatch(ThemeChanger({ ...theme, toggled: 'close' }))
      }
    }
  }
  const ToggleDark = () => {
    dispatch(
      ThemeChanger({
        ...localVariable,
        class: localVariable.class == 'dark' ? 'light' : 'dark',
        dataHeaderStyles: localVariable.class == 'dark' ? 'light' : 'dark',
        dataMenuStyles:
          localVariable.dataNavLayout == 'horizontal' ? (localVariable.class == 'dark' ? 'light' : 'dark') : 'dark',
      })
    )
    const theme = store.getState().theme

    if (theme.class != 'dark') {
      dispatch(
        ThemeChanger({
          ...theme,
          bodyBg: '',
          Light: '',
          darkBg: '',
          inputBorder: '',
        })
      )
      localStorage.setItem('ynexlighttheme', 'light')
      localStorage.removeItem('ynexdarktheme')
      localStorage.removeItem('ynexMenu')
      localStorage.removeItem('ynexHeader')
    } else {
      localStorage.setItem('ynexdarktheme', 'dark')
      localStorage.removeItem('ynexlighttheme')
      localStorage.removeItem('ynexMenu')
      localStorage.removeItem('ynexHeader')
    }
  }
  return (
    <>
      <header className="app-header">
        <nav className="main-header !h-[3.75rem]" aria-label="Global">
          <div className="main-header-container ps-[0.725rem] pe-[1rem] ">
            <div className="header-content-left">
              <div className="header-element">
                <div className="horizontal-logo">
                  <a href={`${import.meta.env.BASE_URL}dashboard`} className="header-logo">
                    <img src={logo1} alt="logo" className="desktop-logo" />
                    <img src={logo1} alt="logo" className="toggle-logo" />
                    <img src={logo1} alt="logo" className="desktop-dark" />
                    <img src={logo1} alt="logo" className="toggle-dark" />
                    <img src={logo1} alt="logo" className="desktop-white" />
                    <img src={logo1} alt="logo" className="toggle-white" />
                  </a>
                </div>
              </div>
              <div className="header-element md:px-[0.325rem] !items-center" onClick={() => toggleSidebar()}>
                <Link
                  aria-label="Hide Sidebar"
                  className="sidemenu-toggle animated-arrow  hor-toggle horizontal-navtoggle inline-flex items-center"
                  to="#"
                >
                  <span></span>
                </Link>
              </div>
              <div className="header-element md:px-[1.325rem] !items-center">
                <CompanyDrop />
              </div>
            </div>

            <div className="header-content-right">
              <div
                className="header-element header-theme-mode hidden !items-center sm:block !py-[1rem] md:!px-[0.65rem] px-2"
                onClick={() => ToggleDark()}
              >
                <Link
                  aria-label="anchor"
                  className="hs-dark-mode-active:hidden flex hs-dark-mode group flex-shrink-0 justify-center items-center gap-2  rounded-full font-medium transition-all text-xs dark:bg-bgdark dark:hover:bg-black/20 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-white dark:focus:ring-white/10 dark:focus:ring-offset-white/10"
                  to="#"
                  data-hs-theme-click-value="dark"
                >
                  <i className="bx bx-moon header-link-icon"></i>
                </Link>
                <Link
                  aria-label="anchor"
                  className="hs-dark-mode-active:flex hidden hs-dark-mode group flex-shrink-0 justify-center items-center gap-2 
             rounded-full font-medium text-defaulttextcolor  transition-all text-xs dark:bg-bodybg dark:bg-bgdark dark:hover:bg-black/20 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-white dark:focus:ring-white/10 dark:focus:ring-offset-white/10"
                  to="#"
                  data-hs-theme-click-value="light"
                >
                  <i className="bx bx-sun header-link-icon"></i>
                </Link>
              </div>
              <div className="header-element md:!px-[0.65rem] px-2 hs-dropdown !items-center ti-dropdown [--placement:bottom-left]">
                <button
                  id="dropdown-profile"
                  type="button"
                  className="hs-dropdown-toggle ti-dropdown-toggle !gap-2 !p-0 flex-shrink-0 sm:me-2 me-0 !rounded-full !shadow-none text-xs align-middle !border-0 !shadow-transparent "
                >
                  <img
                    className="inline-block rounded-full "
                    src={face9}
                    width="32"
                    height="32"
                    alt="Image Description"
                  />
                </button>
                <div className="md:block hidden dropdown-profile cursor-pointer">
                  <p className="font-semibold mb-0 leading-none text-[#536485] text-[0.813rem] ">{`${user?.firstName || ''} ${user?.lastName || ''}`}</p>
                  <span className="opacity-[0.7] font-normal text-[#536485] block text-[0.6875rem] ">
                    {MAP_USER_TYPES[user?.userType]}
                  </span>
                </div>
                <div
                  className="hs-dropdown-menu ti-dropdown-menu !-mt-3 border-0 w-[11rem] !p-0 border-defaultborder hidden main-header-dropdown  pt-0 overflow-hidden header-profile-dropdown dropdown-menu-end"
                  aria-labelledby="dropdown-profile"
                >
                  <ul className="text-defaulttextcolor font-medium dark:text-[#8c9097] dark:text-white/50">
                    <li>
                      <Link
                        className="w-full ti-dropdown-item !text-[0.8125rem] !gap-x-0 !p-[0.65rem] !inline-flex"
                        to="#"
                      >
                        <i className="ti ti-adjustments-horizontal text-[1.125rem] me-2 opacity-[0.7]"></i>Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="w-full ti-dropdown-item !text-[0.8125rem] !p-[0.65rem] !gap-x-0 !inline-flex"
                        to="#"
                        onClick={handleLogout}
                      >
                        <i className="ti ti-logout text-[1.125rem] me-2 opacity-[0.7]"></i>Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <Modalsearch />
    </>
  )
}
const mapStateToProps = (state) => ({
  localVariable: state.theme,
})
export default connect(mapStateToProps, { ThemeChanger })(Header)

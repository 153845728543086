
import React from 'react'
import PropTypes from 'prop-types'


const SearchBox = ({ placeHolder, value, onChange }) => {
    return (
        <span className="flex ms-auto">
            <div className="relative ">
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    className="form-control w-full pr-10 !py-1 !px-2"
                    placeholder={placeHolder || "Search..."}
                />
                {value && (
                    <button
                        type="button"
                        style={{position: 'absolute', top: '0.5rem', right: '0.75rem'}}
                        onClick={() => onChange({ target: { value: '' } })}
                    >
                        ✕
                    </button>
                )}
            </div>
        </span>

    )
}

SearchBox.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeHolder: PropTypes.string,
}

export default SearchBox

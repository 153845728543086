import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CropImage from '@common/src/common/ImageUpload/CropImage'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { modifierAction } from '@common/src/service/cloud/catalog'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { STATUS_OPTIONS } from '@hub/constants/constants'

const validationSchema = Yup.object({
  name: Yup.object()
    .shape({
      en: Yup.string().required('English name is required'),
      ar: Yup.string().required('Arabic name is required'),
    })
    .required('Name is required'),
  description: Yup.object()
    .shape({
      en: Yup.string().required('English description is required'),
      ar: Yup.string().required('Arabic description is required'),
    })
    .required('Description object is required'),
  posId: Yup.string().required('POS ID is required'),
  status: Yup.string().required('Status is required'),
  sortOrder: Yup.number().required('Sort Order is required').min(1),
})

const ModifierForm = ({ id, data, isEdit, onComplete, companyId }) => {
  const toast = useToast()
  const cropImageRef = useRef(null)
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    imageUrl: '',
    posId: '',
    sortOrder: '',
    status: '',
    image:''
  })

  const getSingleModifierData = async () => {
    // const result = await getSingleCompanyDoc(27, CATALOG.MODIFIER_COLLECTION, 100)
    // const modifierData = result.data
    console.log(data)

    setInitialValues({
      name: data?.modItemName || {},
      description: data?.description || {},
      imageUrl: data?.image || '',
      posId: data?.posId || '',
      sortOrder: data?.sortOrder || '',
      status: data?.status || 'ACTIVE',
    })
  }

  useEffect(() => {
    getSingleModifierData()
  }, [])

  const onUploadSuccess = async ({ values, actions, url='' }) => {
    const formData = {
      posId: values.posId,
      sortOrder: values.sortOrder,
      tags: 'chicken',
      modItemName: values.name,
      description: values.description,
      image: url || values.imageUrl,
      modStatus: values.status,
      companyId: parseInt(companyId),
      id: data?.id ? parseInt(data.id) : '',
    }

    try {
      await modifierAction(formData, isEdit)
      const toastMessage = isEdit ? 'Modifier updated successfully!' : 'Modifier added successfully!'
      toast.success(toastMessage)
      actions?.resetForm()
      onComplete?.()
    } catch (error) {
      toast.error(isEdit ? 'Error updating Modifier.' : 'Error creating Modifier.')
      console.error('Form Submission Error:', error)
    }
  }
  const handleSubmit = async (values, actions) => {
    actions?.setSubmitting(true)
    if (values.image) {
      cropImageRef.current.uploadImage()
    } else {
      await onUploadSuccess({values, actions})
    }
  }
  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }
  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <div className="grid grid-cols-12 gap-6 ">
          <div className="xl:col-span-12 col-span-12">
            <div className="box">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <MultiLanguageInput
                      type="input"
                      label="Modifier Name"
                      id="product-name-add"
                      name="name"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Sort Order"
                      name="sortOrder"
                      type="number"
                      placeholder="Sort Order"
                      value={values.sortOrder}
                      onChange={(e) => setFieldValue('sortOrder', parseFloat(e.target.value))}
                      errorText={errors.sortOrder && touched.sortOrder ? errors.sortOrder : null}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="POS ID"
                      name="posId"
                      type="text"
                      placeholder="POS ID"
                      value={values.posId}
                      onChange={(e) => setFieldValue('posId', e.target.value)}
                      errorText={errors.posId && touched.posId ? errors.posId : null}
                    />
                    {/* <ErrorMessage name="posId" component="div" style={{ color: 'red' }} /> */}
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Status"
                      name="status"
                      options={STATUS_OPTIONS}
                      value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                      onChange={(e) => setFieldValue('status', e.value)}
                      errorText={errors.status && touched.status ? errors.status : null}
                    />
                    {/* <ErrorMessage name="status" component="div" style={{ color: 'red' }} /> */}
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="textarea"
                    label="Description"
                    id="category-description-text"
                    name="description"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <label htmlFor="product-image-add" className="form-label">
                    Image
                  </label>
                  <CropImage
                    value={values.imageUrl}
                    onChange={(file) => setFieldValue('image', file)}
                    errorText={touched.image && errors.image ? errors.image : null}
                    ref={cropImageRef}
                    path={`company/${companyId}/modifier/mod_`}
                    onUploadSuccess={onUploadSuccess}
                    onUploadError={onUploadError}
                  />

                  {/* <ErrorMessage name="imageUrl" component="div" style={{ color: 'red' }} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OffCanvasForm>
  )
}

ModifierForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    modItemName: PropTypes.object.isRequired,
    description: PropTypes.object,
    status: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    posId: PropTypes.string.isRequired,
    sortOrder: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
}
export default ModifierForm

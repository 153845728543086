import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CropImage from '@common/src/common/ImageUpload/CropImage';
import PreviewImage from '@hub/components/common/ImageHolders/previewImage';
import AddProductButton from '../../../components/common/buttons/AddProductButton';
import PropTypes from 'prop-types';
import { getStorage, ref as fbRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useSelector } from 'react-redux';
import { IMG_UPLOAD } from '@hub/constants/constants';
import Toggle from '@common/src/common/Toggle/Toggle';

function ChefPicks({ setProductData, productData, handleNext }) {
    
    const [isChefPick, setIsChefPick] = useState(productData?.chefPick || false);
    const [cropFiles, setCropFiles] = useState(['', '', '', '']);
    const [isImage, setIsImage] = useState(
        (productData?.chefPickImages || []).map((image) => !!image)
    );
    const imageRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const { selectedCompany } = useSelector((state) => state.auth);

    const validationSchema = Yup.object().shape(
        ['image1', 'image2', 'image3', 'image4'].reduce((schema, imageKey, index) => {
            schema[imageKey] = !isImage[index]
                ? Yup.mixed()
                    .test('fileRequired', 'Image is required', (value) => value?.name !== undefined)
                    .test('fileFormat', 'Invalid format', (value) => !value || IMG_UPLOAD.CHEF_PICKS.FORMATS.includes(value?.type?.split('/')[1]))
                    .test('fileSize', `Size should be maximum ${IMG_UPLOAD.CHEF_PICKS.MAX_SIZE}`, (value) => !value || value.size <= 1024 * 1024)
                    .required('Image is required')
                : Yup.mixed();
            return schema;
        }, {})
    );

    const handleImageUpload = async (file, index) => {
        const storage = getStorage();
        const path = `company/${selectedCompany.value}/chefPicks/image${index + 1}`;
        const storageRef = fbRef(storage, `${path}_${Date.now()}.${file.name.split('.').pop()}`);
        try {
            await uploadBytes(storageRef, cropFiles[index]);
            return await getDownloadURL(storageRef);
        } catch (error) {
            console.log('Error uploading image:', error);
            return null;
        }
    };

    const handleSubmit = async (values) => {
        console.log('values', values);
        if (isChefPick) {
            const uploadedImages = await uploadAllImages(values);
            setProductData({
                ...productData,
                chefPick: true,
                chefPickImages: uploadedImages,
                companyId: selectedCompany.value,
            });
        } else {
            setProductData({
                ...productData,
                chefPick: false,
                chefPickImages: [],
                companyId: selectedCompany.value,
            });
        }
        // const response = await createProduct(productData);
        // if (response) {
        //     toast.success('Product created successfully');
        //     handleNext();
        // }
        console.log("productData", productData);
        handleNext();
    };

    const uploadAllImages = async (values) => {
        const uploadPromises = [0, 1, 2, 3].map(async (index) => {
            if (imageRefs[index].current) {
                return await handleImageUpload(values[`image${index + 1}`], index);
            }
            return null;
        });
        return await Promise.all(uploadPromises);
    };

    const renderImageField = (index, label, setFieldValue, values, errors, touched) => (
        <div className="col-span-12">
            <label className="form-label">{label}</label>
            {isImage[index] ? (
                <div className="h-1/2 w-1/2">
                    <PreviewImage
                        src={values[`image${index + 1}`]}
                        isRemovable={true}
                        onRemove={() => {
                            setFieldValue(`image${index + 1}`, undefined);
                            setIsImage((prev) => {
                                const newIsImage = [...prev];
                                newIsImage[index] = false;
                                return newIsImage;
                            });
                        }}
                    />
                </div>
            ) : (
                <CropImage
                    value={values[`image${index + 1}`]}
                    onChange={(file) => setFieldValue(`image${index + 1}`, file)}
                    setCropFile={(file) => setCropFiles((prev) => {
                        const newCropFiles = [...prev];
                        newCropFiles[index] = file;
                        return newCropFiles;
                    })}
                    errorText={touched[`image${index + 1}`] && errors[`image${index + 1}`] ? errors[`image${index + 1}`] : null}
                    ref={imageRefs[index]}
                    path={`company/${selectedCompany.value}/chefPicks`}
                    minCrop={IMG_UPLOAD.BANNER.WEB.MIN_RESOLUTION}
                />
            )}
        </div>
    );

    const initialValues = {
        image1: productData?.chefPickImages?.[0] || null,
        image2: productData?.chefPickImages?.[1] || null,
        image3: productData?.chefPickImages?.[2] || null,
        image4: productData?.chefPickImages?.[3] || null,
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={isChefPick && validationSchema}
            onSubmit={handleSubmit}
        >
            {({ setFieldValue, errors, touched, values }) => (
                <Form>
                    <span className="text-sm text-gray-500">Make this product a Chef's Pick</span>
                    <div className="flex items-center mb-4">
                        <Toggle
                            label="Chef's Pick"
                            value={isChefPick}
                            onChange={(e) => setIsChefPick(e.target.checked)}
                            className="mr-4"
                        />
                    </div>
                    {isChefPick && (
                        <div className="grid grid-cols-2 gap-6">
                            {['Home', 'Category'].map((section, sectionIndex) => (
                                <div key={section} className="bg-white rounded p-6">
                                    <h3 className="text-lg font-semibold mb-4">{section}</h3>
                                    {['Web', 'App'].map((platform, platformIndex) => {
                                        const index = sectionIndex * 2 + platformIndex;
                                        return renderImageField(
                                            index,
                                            platform,
                                            setFieldValue,
                                            values,
                                            errors,
                                            touched
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    )}
                    <AddProductButton className="mt-4" />
                </Form>
            )}
        </Formik>
    );
}

ChefPicks.propTypes = {
    setProductData: PropTypes.func.isRequired,
    productData: PropTypes.object.isRequired,
    handleNext: PropTypes.func.isRequired,
};

export default ChefPicks;
// users.js
import PropTypes from 'prop-types'
import logger from '@common/src/lib/logger'
import { db } from '../../firebase/firebaseapi'
import { doc, getDoc } from 'firebase/firestore'
import { COLLECTIONS } from '../../constants/constants'

export const getUser = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, COLLECTIONS.USERS, uid))
    if (userDoc.exists()) {
      const userData = userDoc.data()
      return userData
    } else {
      logger.log('No such user document found!')
    }
  } catch (error) {
    logger.error('Error fetching user data or roles:', error)
  }
}

getUser.propTypes = {
  uid: PropTypes.string.isRequired,
}

import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import AddProductButton from '../../../components/common/buttons/AddProductButton'
import Toggle from '@common/src/common/Toggle/Toggle'
import { createProduct } from '@common/src/service/cloud/catalog'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const Configuration = ({ productData, setProductData }) => {
  const toast = useToast();
  const navigate = useNavigate(); 
  const validationSchema = Yup.object().shape({
    default: Yup.boolean(),
    isRecommend: Yup.boolean(),
    // gotoCartPopupMenu: Yup.boolean(),
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('values', values)

    try {
      setProductData({
        ...productData,
        default: values.default,
        isRecommend: values.isRecommend,
      })

      const response = await createProduct(productData);
      console.log("response", response)
      if (response) {
        toast.success('Product created successfully');
        const path = `${import.meta.env.BASE_URL}product-list`
        navigate(path)
      }
    } catch (error) {
      console.log('error', error)
      console.error('Error creating product:', error.message);
      toast.error('Please complete all steps');
    }
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                initialValues={{
                  default: false,
                  isRecommend: false,
                  //gotoCartPopupMenu: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, errors, touched, values }) => (
                  <Form>
                    <div className="grid grid-cols-2 gap-6">
                      <Toggle
                        label="Is Default"
                        value={values.default}
                        onChange={(e) => setFieldValue('default', e.target.checked)}
                      />
                      <Toggle
                        label="Is Recommended"
                        value={values.isRecommend}
                        onChange={(e) => setFieldValue('isRecommend', e.target.checked)}
                      />
                      {/* <Toggle
                        label="Goto Cart Popup Menu"
                        value={values.gotoCartPopupMenu}
                        onChange={(e) => setFieldValue('gotoCartPopupMenu', e.target.checked)}
                      /> */}
                    </div>
                    <AddProductButton />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Configuration.propTypes = {
  productData: PropTypes.object,
  setProductData: PropTypes.func,
}

export default Configuration

import { useQuery } from '@apollo/client';
import { convertTime } from '@common/src/common/helper';
import { GET_ALL_STORES } from '@common/src/lib/graphql/query/store';
import Pageheader from '@common/src/pageheader/pageheader';
import AvatarImage from '@hub/components/common/ImageHolders/avatarImage';
import Pagination from '@hub/components/common/Pagination/pagination';
import React, { useEffect, useState } from 'react'

import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas.jsx';
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas.js';
import StoreForm from './storeForm';
import SearchBox from '@common/src/common/inputbox/searchBox';

const StoreList = () => {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    totalPages: 0
  });

  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas();
  const [rowData, setRowData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')
  const [info, setInfo] = useState('No stores found.')

  const { data, refetch, loading } = useQuery(GET_ALL_STORES, {
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      search:  searchQuery || undefined
    },
  });

  const onAddClick = () => {
    setRowData(null);
    setIsEdit(false);
    openOffCanvas('addStore', 'Add Store');
  };

  const onEditClick = (storeData) => {
    setRowData(storeData);
    setIsEdit(true);
    openOffCanvas('addStore', 'Edit Store');
  };

  const onComplete = () => {
    refetch();
    closeOffCanvas();
  };

  useEffect(() => {
    if (data) {
      setList(data.storeListQuery.rows);
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.storeListQuery.totalPages,
      }))
    } else {
      setList([]);
      setInfo('No stores match your search.')
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        companyId: 30,
        limit: pagination.limit,
        offset: pagination.offset,
      },
    });
  }, [pagination.offset]);

  return (
    <>
      <Pageheader
        currentpage="Stores"
        activepage="Stores Management "
        mainpage="Stores"
      />
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header flex items-center justify-between">
              <div className="box-title">Stores</div>
              <SearchBox
                placeHolder="Search by store name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="flex items-center">
                <button type="button" onClick={onAddClick} className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2">
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Store
                </button>
              </div>
            </div>

            <div className="box-body">
              <div className="table-responsive mb-4">

                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                        Sl.No
                      </th>
                      <th scope="col" className="text-start">
                        Store Name
                      </th>
                      <th scope="col" className="text-start">
                        Company Name
                      </th>
                      <th scope="col" className="text-start">
                        Services
                      </th>
                      <th scope="col" className="text-start">
                        Status (Open/Closed)
                      </th>
                      <th scope="col" className="text-start">
                        Operating Hours
                      </th>
                      <th scope="col" className="text-start">
                        Contact Information
                      </th>
                      <th scope="col" className="text-start">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {list.length > 0 ? (
                          list.map((item) => (
                            <tr className="product-list" key={item.id}>
                              <td>{(pagination.currentPage - 1) * pagination.limit + list.indexOf(item) + 1}</td>
                              <td>
                                <div className="flex items-center">
                                  <div className="me-2">
                                    <AvatarImage src={item?.photo} />
                                  </div>
                                  <div className="font-semibold">{item.name.en}</div>
                                </div>
                              </td>
                              <td>{item.company?.name?.en}</td>
                              <td>
                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                  {item?.services?.map((service) => (
                                    <li key={service}>{service}</li>
                                  ))}
                                </ul>
                              </td>
                              <td>{item.isClosed ? 'Closed' : 'Open'}</td>
                              <td>{`${convertTime(item?.open)} to ${convertTime(item?.close)}`}</td>
                              <td>
                                {item?.address?.street?.en || item?.address?.street?.ar} <br />
                                {item?.address?.city?.en || item?.address?.city?.ar} <br />
                                {item?.phone}
                              </td>
                              <td>
                                <div className="space-x-1 rtl:space-x-reverse">
                                  <button
                                    onClick={() => onEditClick(item)}
                                    className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
                                  >
                                    <i className="ri-pencil-line"></i>
                                  </button>
                                  <button
                                    onClick={() => onDeleteClick(item)}
                                    className="ti-btn ti-btn-danger ti-btn-icon ti-btn-sm"
                                  >
                                    <i className="ri-spam-3-line"></i>
                                  </button>
                                </div>
                              </td>

                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              <div className="alert alert-info">{info}</div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} setPagination={setPagination} rows={list} />
            </div>
          </div>
        </div>
      </div>
      <FloatingCanvas onClose={closeOffCanvas} isVisible={isVisible} title={offCanvasTitle}>
        {activeComponent === 'addStore' && (
          <StoreForm data={rowData} isEdit={isEdit} onComplete={onComplete} />
        )}
      </FloatingCanvas>
    </>
  )
}

export default StoreList

import React from 'react'
import Select from 'react-select'

const SelectBox = ({
  id,
  label,
  options,
  placeholder,
  value,
  onChange,
  errorText,
  className = '',
  labelClassName = '',
  isSearchable = true,
  menuPlacement = 'auto',
  classNamePrefix = 'Select2',
  ...rest
}) => {
  return(
  <div className={`select-box-container ${className}`}>
    {label && (
      <label htmlFor={id} className={`form-label mt-3 ${labelClassName}`}>
        {label}
      </label>
    )}
    <Select
      className="ti-form-select rounded-sm !p-0"
      id={id}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      isSearchable={isSearchable}
      menuPlacement={menuPlacement}
      // className={`w-full !rounded-md`}
      classNamePrefix={classNamePrefix}
      {...rest}
    />
    {errorText && (
      <label htmlFor={id} className="mt-1 text-[0.75rem] opacity-[0.8] !mb-0" style={{ color: 'red' }}>
        {errorText}
      </label>
    )}
  </div>
)}

export default SelectBox

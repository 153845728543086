export const MENUITEMS = [
  {
    menutitle: 'Menu',
  },
  {
    path: `${import.meta.env.BASE_URL}company`,
    title: 'Company Management',
    icon: <i className="bx bx-building side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    key:"company-management",
    children: [
      {
        path: `${import.meta.env.BASE_URL}companies`,
        title: 'Companies',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'companies',
      }
    ]
  },
  {
    title: 'User Management',
    icon: <i className="bx bx-user side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    children: [
      {
        path: `${import.meta.env.BASE_URL}users`,
        title: 'Users',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'accounts',
      },
      {
        path: `${import.meta.env.BASE_URL}roles`,
        title: 'Roles',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'accounts',
      },
    ],
  },
  {
    title: 'Store Management',
    icon: <i className="bx bx-store-alt side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    key: 'store',
    children: [
      {
        path: `${import.meta.env.BASE_URL}store`,
        title: 'Stores',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'store',
      }
    ]
  },
  {
    title: 'Catalog Management',
    icon: <i className="bx bx-food-menu side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    children: [
      {
        path: `${import.meta.env.BASE_URL}category`,
        title: 'Category',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'catalog',
      },
      {
        path: `${import.meta.env.BASE_URL}modifier`,
        title: 'Modifier',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'catalog',
      },
      {
        path: `${import.meta.env.BASE_URL}modifier-group`,
        title: 'Modifier Group',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'catalog',
      },
      {
        path: `${import.meta.env.BASE_URL}menu-management`,
        title: 'Menu Management',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'catalog',
      },
    ],
  },
  {
    path: `${import.meta.env.BASE_URL}customer`,
    icon: <i className="bi bi-people side-menu__icon"></i>,
    type: 'link',
    selected: false,
    dirchange: false,
    active: false,
    title: 'Customer Management',
    key: 'customer',
  },
  {
    title: 'Promotions',
    icon: <i className="bx bx-layer side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    children: [
      {
        path: `${import.meta.env.BASE_URL}banners`,
        title: 'Banners',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'promotion',
      },
      {
        path: `${import.meta.env.BASE_URL}push-notifications`,
        title: 'Push Notifications',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'promotion',
      },
      {
        path: `${import.meta.env.BASE_URL}promo-codes`,
        title: 'Promo codes',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'promotion',
      },
    ],
  },
  {
    path: `${import.meta.env.BASE_URL}sales-analysis`,
    icon: <i className="bi bi-graph-up-arrow side-menu__icon"></i>,
    type: 'link',
    selected: false,
    dirchange: false,
    active: false,
    title: 'Sales Analysis',
    key: 'dashboard',
  },
  {
    title: 'App Orders',
    icon: <i className="bi bi-cart-check-fill side-menu__icon"></i>,
    type: 'sub',
    selected: false,
    dirchange: false,
    active: false,
    children: [
      {
        path: `${import.meta.env.BASE_URL}order-management`,
        title: 'Order Management',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'appOrders',
      },
      {
        path: `${import.meta.env.BASE_URL}analytical-view`,
        title: 'Analytical View',
        type: 'link',
        active: false,
        selected: false,
        dirchange: false,
        key: 'appOrders',
      },
    ],
  },
  {
    path: `${import.meta.env.BASE_URL}settings`,
    icon: <i className="bx bx-cog side-menu__icon"></i>,
    type: 'link',
    selected: false,
    dirchange: false,
    active: false,
    title: 'Settings',
    key: 'settings',
  },
  {
    path: `${import.meta.env.BASE_URL}testimonial-management`,
    icon: <i className="bx bx-star side-menu__icon"></i>,
    type: 'link',
    selected: false,
    dirchange: false,
    active: false,
    title: 'Testimonial Management',
    key: 'feedback',
  },
]

import { useEffect, useMemo, useRef, useState } from 'react'
import { collection, doc, deleteDoc } from 'firebase/firestore'
import { useSelector } from 'react-redux'

import TableList from '@common/src/common/Table/TableList'
import PageHeader from '@common/src/pageheader/pageheader'
import FloatingCanvas from '@common/src/common/offcanvas/floatingCanvas.jsx'
import useFloatingCanvas from '@common/src/hooks/useFloatingCanvas.js'
import { COLLECTIONS } from '@common/src/constants/constants'

import { CATALOG } from '@hub/constants/constants'
import { db } from '@hub/firebase/firebaseapi'
import NotificationForm from './notificationForm'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import PropTypes from 'prop-types'

const NotificationImage = ({ image }) => {
  const [imageUrl, setImageUrl] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (image instanceof File) {
      const url = URL.createObjectURL(image)
      setImageUrl(url)
      setError(false)

      // Cleanup function
      return () => {
        URL.revokeObjectURL(url)
      }
    } else if (typeof image === 'string') {
      setImageUrl(image)
      setError(false)
    }
  }, [image])

  if (error || !imageUrl) {
    return (
      <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center">
        <span className="text-xs text-gray-500">No Image</span>
      </div>
    )
  }

  return (
    <img src={imageUrl} alt="Notification" className="w-12 h-12 object-cover rounded" onError={() => setError(true)} />
  )
}

const getColums = (onEditClick, onDeleteClick) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name.en',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description.en',
      key: 'desc',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => <NotificationImage image={image} />,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <>{text}</>,
    },
    {
      title: 'Redirection Url',
      dataIndex: 'launchUrl',
      key: 'launchUrl',
      render: (url) =>
        url ? (
          <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
            Open Link
          </a>
        ) : (
          <span className="text-gray-500">No URL</span>
        ),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'text-start',
      render: (text, record) => (
        <div className="space-x-1 rtl:space-x-reverse">
          <button
            onClick={() => onEditClick(record)}
            aria-label="button"
            type="button"
            className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
          >
            <i className="ri-pencil-line"></i>
          </button>
          <button
            onClick={() => onDeleteClick(record.id)}
            aria-label="button"
            type="button"
            className="ti-btn ti-btn-danger ti-btn-icon ml-5 ms-1 ti-btn-sm invoice-btn"
          >
            <i className="ri-delete-bin-5-line"></i>
          </button>
        </div>
      ),
    },
  ]
}
const pagination = {
  pageSize: 20,
}

const Notifications = () => {
  const tableRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [dbRef, setDbRef] = useState(null)
  const [data, setData] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedNotificationId, setSelectedNotificationId] = useState(null)
  const { isVisible, activeComponent, offCanvasTitle, openOffCanvas, closeOffCanvas } = useFloatingCanvas()
  const toast = useToast()

  useEffect(() => {
    if (selectedCompany) {
      setDbRef(collection(db, `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${CATALOG.NOTIFICATION}`))
    }
  }, [selectedCompany])

  const onEditClick = (data) => {
    setData(data)
    setIsEdit(true)
    openOffCanvas('addNotification', 'Edit Notification')
  }

  const onDeleteClick = (id) => {
    setSelectedNotificationId(id)
    setIsModalVisible(true) // Show the confirmation modal
  }

  const handleDelete = async () => {
    try {
      const notificationRef = doc(
        db,
        `${COLLECTIONS.COMPANIES}/${selectedCompany.value}/${CATALOG.NOTIFICATION}`,
        selectedNotificationId
      )
      await deleteDoc(notificationRef)
      toast.success('Notification deleted successfully')

      // Refresh the table to reflect the changes
      if (tableRef.current) {
        tableRef.current.refreshCurrentPage()
      }

      setIsModalVisible(false) // Close the modal after successful deletion
    } catch (error) {
      toast.error('Error deleting notification')
      console.error('Error deleting notification: ', error)
      setIsModalVisible(false) // Close the modal in case of an error
    }
  }

  const columns = useMemo(() => getColums(onEditClick, onDeleteClick), [onEditClick, onDeleteClick])

  const onComplete = () => {
    if (tableRef.current) {
      tableRef.current.refreshCurrentPage()
      closeOffCanvas()
    }
  }
  const onClose = () => {
    closeOffCanvas(false)
  }
  const onAddClick = () => {
    setData(null)
    setIsEdit(false)
    openOffCanvas('addNotification', 'Add Notification')
  }

  return (
    <>
      <PageHeader currentpage="Promotions" activepage="Promotions" mainpage="Push Notifications" />

      {dbRef && (
        <TableList
          name="Notifications"
          columns={columns}
          dbRef={dbRef}
          pagination={pagination}
          tools={{
            btnName: 'Add Notification',
            btnClick: onAddClick,
          }}
          ref={tableRef}
        />
      )}

      <FloatingCanvas onClose={onClose} isVisible={isVisible} close={closeOffCanvas} title={offCanvasTitle}>
        {activeComponent === 'addNotification' && (
          <NotificationForm data={data} isEdit={isEdit} onComplete={onComplete} />
        )}
      </FloatingCanvas>
      {isModalVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h3 className="text-lg font-semibold text-center">Are you sure you want to delete this notification?</h3>
            <div className="mt-4 flex justify-center space-x-4">
              <button onClick={handleDelete} className="bg-danger text-white px-4 py-2 rounded hover:bg-red-700">
                Delete
              </button>
              <button
                onClick={() => setIsModalVisible(false)}
                className="bg-primary text-white px-4 py-2 rounded hover:bg-blue-700"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
NotificationImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]).isRequired,
}
export default Notifications

import React from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import * as Yup from 'yup'
import AddProductButton from '../../../components/common/buttons/AddProductButton'
import PropTypes from 'prop-types'

const Pricing = ({ handleNext, handleBack, setProductData, productData }) => {
  const validationSchema = Yup.object().shape({
    sortOrder: Yup.number()
      .typeError('Sort Order must be a number')
      .min(1, 'Sort Order must be greater than zero')
      .required('Sort Order is required'),
    defaultSalesPrice: Yup.number()
      .typeError('Default Price must be a number')
      .min(1, 'Default Price must be greater than zero')
      .required('Default Price is required'),
    marketPrice: Yup.number()
      .typeError('Market Price must be a number')
      .min(1, 'Market Price must be greater than zero')
      .required('Market Price is required'),
    service: Yup.array()
      .min(1, 'At least one transaction type is required')
      .required('Transaction Type is required'),
  })
  const handleSubmit = async (values, { setSubmitting }) => {
    setProductData({ ...productData, 
      sortOrder: values.sortOrder,
      defaultSalesPrice: values.defaultSalesPrice,
      marketPrice: values.marketPrice,
      service: values.service.map((service) => service.value)
     })

    handleNext()
  }

  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                initialValues={{
                  sortOrder:productData?.sortOrder || 0,
                  defaultSalesPrice: productData?.defaultSalesPrice || 0,
                  marketPrice:productData?.marketPrice || 0,
                  service: productData?.service || [],
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, errors, touched, values }) => (
                  <Form>
                    {console.log('values', errors)}
                    <div className="grid grid-cols-2 gap-6">
                      <div className="xl:col-span-1 col-span-12">
                        <InputBox
                          label="Sort Order"
                          name="sortOrder"
                          type="number"
                          placeholder="Sort Order"
                          value={values.sortOrder}
                          onChange={(e) => setFieldValue('sortOrder', parseInt(e.target.value))}
                          error={errors.sortOrder && touched.sortOrder ? errors.sortOrder : null}
                        />
                        <ErrorMessage name="sortOrder" component="div" style={{ color: 'red' }} />
                      </div>
                      <div className="xl:col-span-1 col-span-12">
                        <InputBox
                          label="Default Price"
                          name="defaultSalesPrice"
                          type="number"
                          placeholder="Default Price"
                          value={values.defaultSalesPrice}
                          onChange={(e) => setFieldValue('defaultSalesPrice', parseFloat(e.target.value))}
                          error={errors.defaultSalesPrice && touched.defaultSalesPrice ? errors.defaultSalesPrice : null}
                        />
                        <ErrorMessage name="defaultSalesPrice" component="div" style={{ color: 'red' }} />
                      </div>
                      <div className="xl:col-span-1 col-span-12">
                        <InputBox
                          label="Market Price"
                          name="marketPrice"
                          type="number"
                          placeholder="Market Price"
                          value={values.marketPrice}
                          onChange={(e) => setFieldValue('marketPrice', parseFloat(e.target.value))}
                        />
                        <ErrorMessage name="marketPrice" component="div" style={{ color: 'red' }} />
                      </div>
                      <div className="xl:col-span-1 col-span-12">
                        <SelectBox
                          label="Transaction Type"
                          name="service"
                          options={[
                            { value: 'DELIVERY', label: 'Delivery' },
                            { value: 'TAKEAWAY', label: 'Takeaway' },
                            { value: 'GLOBAL_DELIVERY', label: 'Global Delivery' },
                            { value: 'GLOBAL_TAKEAWAY', label: 'Global Takeaway' },
                          ]}
                          isMulti={true}
                          value={values.service}
                          onChange={(e) => setFieldValue('service', e)}
                          error={errors.service && touched.service ? errors.service : null}
                        />
                        <ErrorMessage name="service" component="div" style={{ color: 'red' }} />
                      </div>
                    </div>
                    <AddProductButton handleBack={handleBack} />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Pricing.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  setProductData: PropTypes.func.isRequired,
  productData: PropTypes.array,
}

export default Pricing

// action types
export const FETCH_USER_DETAILS_REQUEST = 'FETCH_USER_DETAILS_REQUEST'
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS'
export const FETCH_USER_DETAILS_FAILURE = 'FETCH_USER_DETAILS_FAILURE'

// user object containing permissions and company list
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY'

// actions for fetching user details
export const fetchUserDetailsRequest = (uid) => ({
  type: FETCH_USER_DETAILS_REQUEST,
  uid,
})

export const fetchUserDetailsSuccess = (userData, permissions, companies, menuItems) => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload: { userData, permissions, companies, menuItems },
})

export const fetchUserDetailsFailure = (error) => ({
  type: FETCH_USER_DETAILS_FAILURE,
  error,
})

// action for setting selected company
export const setSelectedCompany = (company) => ({
  type: SET_SELECTED_COMPANY,
  company,
})

import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'

import { CATALOG, GROUP_TYPES, STATUS_OPTIONS } from '@hub/constants/constants'
import { getPaginatedData } from '../api'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { modifierGroupAction } from '@common/src/service/cloud/catalog'

const validationSchema = Yup.object({
  name: Yup.object()
    .shape({
      en: Yup.string().required('English name is required'),
      ar: Yup.string().required('Arabic name is required'),
    })
    .required('Name is required'),
  modGroupType: Yup.string().required('Type is required'),
  status: Yup.string().required('Status is required'),
  posId: Yup.string().required('POS ID is required'),
  modifiers: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required('Modifier ID is required'),
        price: Yup.number().required('Price is required').min(0.1, 'Price must be greater than 0'),
      })
    )
    .min(1, 'At least one modifier must be selected'),
})

const ModifierGroupForm = ({ id, data, isEdit, onComplete, companyId }) => {
  //const { id } = useParams();
  const toast = useToast()
  const [modifiersOptions, setModifiersOptions] = useState([])
  const [modifiersList, setModifiersList] = useState([])
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    status: '',
    modGroupType: '',
    posId: '',
    modifiers: [], // To store selected modifiers
  })

  const fetchModifiersList = async () => {
    const result = await getPaginatedData(companyId, CATALOG.MODIFIER_COLLECTION, 10, '', 'posId')
    const options = result.data.map((modifier) => ({
      value: modifier.id,
      id: modifier.id,
      label: modifier.modItemName ? modifier.modItemName.en : modifier.name.en,
      price: modifier.price,
      status: modifier.status,
    }))
    setModifiersOptions(options)
  }

  // Function to handle adding a new modifier row
  const handleAddModifier = (event) => {
    event.preventDefault()
    // Check if any of the existing modifiers have an empty price
    const hasEmptyPrice = modifiersList.some((modifier) => !modifier.price)

    if (hasEmptyPrice) {
      // Show an error message or alert if a price is missing
      toast.error('Please add a price for all modifiers before adding a new one.')
      return // Prevent adding a new modifier
    }

    // If all modifiers have a price, proceed to add a new modifier row
    const newModifier = { id: '', price: '', status: 'ACTIVE' }
    setModifiersList([...modifiersList, newModifier])
    setInitialValues({ ...initialValues, modifiers: [...modifiersList, newModifier] })
  }

  // Function to handle deleting a modifier row
  const handleDeleteModifier = (index) => {
    const updatedList = [...modifiersList]
    updatedList.splice(index, 1) // Remove the row at the given index
    setModifiersList(updatedList)
    setInitialValues({ ...initialValues, modifiers: updatedList })
  }

  // Function to handle selecting a modifier from the dropdown
  const handleSelectModifier = (index, selectedData) => {
    const updatedList = [...modifiersList]
    updatedList[index] = selectedData // Update the modifier id in the row
    setModifiersList(updatedList)
    setInitialValues({ ...initialValues, modifiers: updatedList })
  }

  // Function to handle price input change
  const handlePriceChange = (index, price) => {
    const updatedList = [...modifiersList]
    updatedList[index].price = price // Update the price in the row
    setModifiersList(updatedList)
    setInitialValues({ ...initialValues, modifiers: updatedList })
  }

  // Filter available options to avoid selecting the same modifier twice
  const availableModifiersOptions = modifiersOptions.filter(
    (option) => !modifiersList.some((modifier) => modifier.id === option.id)
  )

  const getSingleModifierGroupData = async () => {
    // const result = await getSingleCompanyDoc(27, CATALOG.MODIFIER_GROUP_COLLECTION, 'ID_CHOICE_OF_ADD_ONS') // Adjust the parameters to your API

    setInitialValues({
      name: data?.modGroupName || {},
      description: data?.description || {},
      modGroupType: data?.modGroupType || '',
      status: data?.status || 'ACTIVE',
      posId: data?.posId || '',
      modifiers: data?.modifiers || [],
    })
    setModifiersList(data?.modifiers || [])

    // setIsEditMode(true)
  }

  useEffect(() => {
    fetchModifiersList()
    getSingleModifierGroupData()
  }, [])

  const handleSubmit = async (values, actions) => {
    const modifiersListData = values.modifiers.map((modifier) => ({
      id: parseInt(modifier.id),
      status: modifier?.status || 'ACTIVE',
      price: parseFloat(modifier.price),
    }))

    const formData = {
      modGroupName: values.name,
      description: values.description,
      modGroupType: values.modGroupType,
      status: values.status,
      posId: values.posId,
      modifiers: modifiersListData,
      companyId: companyId,
      id: data?.id ? parseInt(data.id) : '',
    }

    try {
      await modifierGroupAction(formData, isEdit) // Submit the form data

      const toastMessage = isEdit ? 'Modifier group updated successfully!' : 'Modifier group successfully!'
      toast.success(toastMessage) // Show success toast

      actions?.resetForm() // Reset the form after successful submission
      onComplete?.() // Call onComplete callback if provided
    } catch (error) {
      toast.error(isEdit ? 'Error updating Category.' : 'Error creating Category.')
    }
  }

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
    >
      {({ setFieldValue, values, errors, touched }) => {
        return (
          <div className="grid grid-cols-12 gap-6 ">
            <div className="xl:col-span-12 col-span-12">
              <div className="box">
                <div className="box-body add-products !p-0">
                  <div className="grid grid-cols-1 gap-6">
                    <MultiLanguageInput
                      type="input"
                      label="Modifier Group Name"
                      id="product-name-add"
                      name="name"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    <div className="xl:col-span-1 col-span-12">
                      <div className="grid grid-cols-2 gap-6">
                        <div className="xl:col-span-1 col-span-12">
                          <SelectBox
                            label="Type"
                            name="modGroupType"
                            options={GROUP_TYPES}
                            value={GROUP_TYPES.find((option) => option.value === values.modGroupType)}
                            onChange={(e) => setFieldValue('modGroupType', e.value)}
                            error={errors.modGroupType && touched.modGroupType ? errors.modGroupType : null}
                          />
                        </div>
                        <div className="xl:col-span-1 col-span-12">
                          <SelectBox
                            label="Status"
                            name="status"
                            options={STATUS_OPTIONS}
                            value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                            onChange={(e) => setFieldValue('status', e.value)}
                            error={errors.status && touched.status ? errors.status : null}
                          />
                          {/* <ErrorMessage name="status" component="div" style={{ color: 'red' }} /> */}
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-1 col-span-12">
                      <InputBox
                        label="POS ID"
                        name="posId"
                        type="text"
                        placeholder="POS ID"
                        value={values.posId}
                        onChange={(e) => setFieldValue('posId', e.target.value)}
                        error={errors.posId && touched.posId ? errors.posId : null}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-6">
                    <MultiLanguageInput
                      type="textarea"
                      label="Description"
                      id="category-description-text"
                      name="description"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </div>

                  <div className="grid grid-cols-1 gap-6">
                    <div>
                      <button
                        onClick={handleAddModifier}
                        className="mt-4 ti-btn ti-btn-success ti-btn-loader m-1"
                      >Add</button>

                      <table className="ti-custom-table ti-custom-table-head ti-custom-table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Modifier</th>
                            <th scope="col">Status</th>
                            <th scope="col">Price</th>
                            <th scope="col" className="!text-end">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {modifiersList.map((modifier, index) => (
                            <tr key={modifier.id}>
                              <td>
                                <SelectBox
                                  name={`modifiers[${index}].id`}
                                  options={availableModifiersOptions}
                                  value={modifiersOptions.find((option) => option.id === modifier.id) || null}
                                  onChange={(selectedOption) => handleSelectModifier(index, selectedOption)}
                                  error={null} // Handle validation if necessary
                                />
                              </td>
                              <td>{modifier.status}</td>
                              <td>
                                <InputBox
                                  name={`modifiers[${index}].price`}
                                  type="number"
                                  placeholder="Enter price"
                                  value={modifier.price}
                                  onChange={(e) => {
                                    handlePriceChange(index, e.target.value)
                                    console.log(errors)
                                  }}
                                  errorText={errors.modifiers?.[index]?.price || null}
                                  inputClassName="block"
                                />
                              </td>
                              <td className="p-3 whitespace-nowrap text-end text-sm font-medium">
                                <button
                                  onClick={() => handleDeleteModifier(index)}
                                  type="button"
                                  className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 focus:outline-none focus:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:text-blue-400"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </OffCanvasForm>
  )
}

ModifierGroupForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    modGroupName: PropTypes.object.isRequired,
    description: PropTypes.object,
    modGroupType: PropTypes.string.isRequired,
    status: PropTypes.string,
    posId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    modifiers: PropTypes.array.isRequired,
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default ModifierGroupForm

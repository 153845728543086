import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CropImage from '@common/src/common/ImageUpload/CropImage'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { storeAction } from '@common/src/service/cloud/catalog'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { STATUS_OPTIONS, CURRENCY_OPTIONS, SERVICE_OPTIONS } from '@hub/constants/constants'
import { getCountries, getCompanies } from '../../../shared/src/service/firebase/api'

const validationSchema = Yup.object({
  name: Yup.object({
    en: Yup.string().required('English name is required'),
    ar: Yup.string().required('Arabic name is required'),
  }).required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  status: Yup.string().required('Status is required'),
  nationality: Yup.string().required('Nationality is required'),
  currency: Yup.string().required('Currency is required'),
  outletCode: Yup.string().required('Outlet Code is required'),
  timezone: Yup.string().required('Timezone is required'),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, 'Phone number must contain only digits')
    .required('Phone number is required'),
  address: Yup.object({
    city: Yup.string().required('City is required'),
    street: Yup.string().required('Location is required'),
    address: Yup.string().required('Address is required'),
  }).required('Address is required'),
  latitude: Yup.number()
    .required('Latitude is required')
    .min(-90, 'Latitude must be between -90 and 90')
    .max(90, 'Latitude must be between -90 and 90'),
  longitude: Yup.number()
    .required('Longitude is required')
    .min(-180, 'Longitude must be between -180 and 180')
    .max(180, 'Longitude must be between -180 and 180'),
  open: Yup.string().required('Open time is required'),
  close: Yup.string()
    .required('Close time is required')
    .test('is-after-open', 'Close time must be after open time', function (value) {
      const { open } = this.parent
      return open && value && value > open
    }),
  config: Yup.object().shape({
    deliveryConfig: Yup.object().shape({
      minCartValue: Yup.number()
        .required('Minimum cart value is required')
        .min(1, 'Minimum cart value should be at least 1'),
      minDeliveryCost: Yup.number()
        .required('Minimum delivery cost is required')
        .min(1, 'Minimum delivery cost should be at least 1'),
      minDeliveryForKm: Yup.number()
        .required('Minimum delivery distance is required')
        .min(1, 'Minimum delivery distance should be at least 1 km'),
      costPerKm: Yup.number().required('Cost per km is required').min(1, 'Cost per km should be at least 1'),
      maxDeliveryDistance: Yup.number()
        .required('Maximum delivery distance is required')
        .min(1, 'Maximum delivery distance should be at least 1')
        .test('maxDeliveryDistance-decimal', 'Maximum delivery distance can have up to 2 decimal places', (value) =>
          /^\d+(\.\d{1,2})?$/.test(value)
        ),
    }),
  }),
})

const StoreForm = ({ id, data, isEdit, onComplete }) => {
  console.log(data)
  const toast = useToast()
  const cropImageRef = useRef(null)
  const [phoneCode, setPhoneCode] = useState('+1')
  const [countryOptions, setCountryOptions] = useState([])
  const [companyOptions, setCompanyOptions] = useState([])

  const [initialValues, setInitialValues] = useState({
    companyId: '',
    name: { en: '', ar: '' },
    email: '',
    status: '',
    nationality: '',
    timezone: '',
    currency: '',
    phoneNumber: '',
    countryCode: '',
    outletCode: '',
    latitude: '',
    longitude: '',
    services: [],
    address: { city: '', street: '', address: '' },
    open: '',
    close: '',
    config: {
      deliveryConfig: {
        minCartValue: '',
        minDeliveryCost: '',
        minDeliveryForKm: '',
        costPerKm: '',
        maxDeliveryDistance: '',
      },
    },
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [countries, companies] = await Promise.all([getCountries(), getCompanies()])

        const countriesList = countries.map((doc) => ({
          label: doc.name,
          value: doc,
        }))
        setCountryOptions(countriesList)
        setCompanyOptions(companies)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    const getCoordinates = (coordinates) =>
    Array.isArray(coordinates) ? coordinates : [null, null];

  const getFormattedAddress = (address) =>
    address
      ? {
          city: address.city?.en || '',
          street: address.street?.en || '',
          address: address.address?.en || '',
          nationality: address.nationality?.en || '',
        }
      : { city: '', street: '', address: '', nationality: '' };

  const getFilteredServices = (services) =>
    SERVICE_OPTIONS.filter((item) => (services || []).includes(item.value));

  const initializeValues = (data) => {
    if (!data) return;

    const [lat, long] = getCoordinates(data?.location?.coordinates);
    const formattedAddress = getFormattedAddress(data?.address);
    const filteredServices = getFilteredServices(data?.services);

    setInitialValues({
      companyId: parseInt(data.company?.id || '', 10),
      name: data.name || { en: '', ar: '' },
      email: data.storeContacts.email || '',
      status: data.status || 'ACTIVE',
      timezone: data.timeZone || '',
      currency: data.currency || '',
      phoneNumber: data.storeContacts.phone || '',
      nationality: data?.address?.nationality?.en || '',
      outletCode: data.outletCode || '',
      latitude: lat || '',
      longitude: long || '',
      address: formattedAddress,
      services: filteredServices,
      open: data.open || '',
      close: data.close || '',
      phoneCode: data.phoneCode,
      countryCode: data.countryCode,
      imageUrl: data.photo,
      config: {
        deliveryConfig: {
          minCartValue: data.config?.deliveryConfig?.minCartValue || '',
          minDeliveryCost: data.config?.deliveryConfig?.minDeliveryCost || '',
          minDeliveryForKm: data.config?.deliveryConfig?.minDeliveryForKm || '',
          costPerKm: data.config?.deliveryConfig?.costPerKm || '',
          maxDeliveryDistance: data.config?.deliveryConfig?.maxDeliveryDistance || '',
        },
      },
    });
  };

  fetchData()
  initializeValues(data);
  }, [data])

  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }

  const handleSubmit = async (values, actions) => {
    actions?.setSubmitting(true)
    if (values.image) {
      cropImageRef.current.uploadImage()
    } else {
      await onUploadSuccess({ values, actions })
    }
  }
  const onUploadSuccess = async ({ values, actions, url = '' }) => {
    const selectedValues = values.services ? values.services.map((option) => option.value) : []

    const formData = {
      companyId: parseInt(values.companyId, 10), // Ensure integer type
      name: {
        en: values.name.en,
        ar: values.name.ar,
      },
      storeContacts: {
        email: values.email,
        phone: values.phoneNumber,
        phoneCode: phoneCode,
      },
      photo: url,
      address: {
        street: {
          en: values.address.street,
          ar: values.address.street,
        },
        city: {
          en: values.address.city,
          ar: values.address.city,
        },
        address: {
          en: values.address.address,
          ar: values.address.address,
        },
        nationality: {
          en: values.nationality,
          ar: values.nationality,
        },
      },
      location: `${values.latitude},${values.longitude}`, // Kept as a string
      status: values.status,
      countryCode: values.countryCode,
      outletCode: values.outletCode,
      timeZone: values.timezone,
      services: selectedValues,
      open: values.open,
      close: values.close,
      currency: values.currency,
      maxDeliveryDistance: parseInt(values.config.deliveryConfig.maxDeliveryDistance, 10), // Ensure integer type
      promise: 25, // Integer constant as specified
      config: {
        deliveryConfig: {
          minCartValue: parseInt(values.config.deliveryConfig.minCartValue, 10),
          minDeliveryCost: parseInt(values.config.deliveryConfig.minDeliveryCost, 10),
          minDeliveryForKm: parseInt(values.config.deliveryConfig.minDeliveryForKm, 10),
          costPerKm: parseInt(values.config.deliveryConfig.costPerKm, 10),
          maxDeliveryDistance: parseInt(values.config.deliveryConfig.maxDeliveryDistance, 10),
        },
      },
      id: data?.id ? parseInt(data.id, 10) : null, // Cast id to integer if it exists
    }
    console.log(formData)
    try {
      await storeAction(formData, isEdit)
      const toastMessage = isEdit ? 'Store updated successfully!' : 'Store added successfully!'
      toast.success(toastMessage)
      actions?.resetForm()
      onComplete?.()
    } catch (error) {
      toast.error(isEdit ? 'Error updating Store.' : 'Error creating Store.')
      console.error('Form Submission Error:', error)
    }
  }

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText="Cancel"
      id={id}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <div className="grid grid-cols-12 gap-6 ">
          <div className="xl:col-span-12 col-span-12">
            <div className="box">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="input"
                    label="Store Name"
                    id="store-name-add"
                    name="name"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Company"
                      name="companyId"
                      value={companyOptions.find((option) => option?.value === values.companyId)}
                      options={companyOptions}
                      onChange={(e) => {
                        setFieldValue('companyId', e.value)
                      }}
                      errorText={errors.companyId && touched.companyId ? errors.companyId : null}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="email"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      errorText={errors.email && touched.email ? errors.email : null}
                    />
                    {/* <ErrorMessage name="defaultSalesPrice" component="div" style={{ color: 'red' }} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Nationality"
                      name="nationality"
                      value={countryOptions.find((option) => option?.value?.name === values.nationality)}
                      options={countryOptions}
                      onChange={(e) => {
                        setPhoneCode(`+${e.value.phoneCode}`)
                        setFieldValue('nationality', e.value.name)
                        setFieldValue('countryCode', e.value.iso)
                        setFieldValue('currency', e.value.currency)
                        setFieldValue('timezone', e.value.timeZone)
                      }}
                      errorText={errors.nationality && touched.nationality ? errors.nationality : null}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Currency"
                      name="currency"
                      disabled={true}
                      options={CURRENCY_OPTIONS}
                      value={CURRENCY_OPTIONS.find((option) => option.value === values.currency)}
                      onChange={(e) => {
                        setFieldValue('currency', e.value)
                      }}
                      errorText={errors.currency && touched.currency ? errors.currency : null}
                    />
                    {/* <ErrorMessage name="price" component="div" style={{ color: 'red' }} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Outlet Code"
                      type="text"
                      name="outletCode"
                      value={values.outletCode}
                      onChange={(e) => setFieldValue('outletCode', e.target.value)}
                      errorText={errors.outletCode && touched.outletCode ? errors.outletCode : null}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Timezone"
                      type="text"
                      name="timezone"
                      value={values.timezone}
                      onChange={(e) => setFieldValue('timezone', e.target.timezone)}
                      errorText={errors.timezone && touched.timezone ? errors.timezone : null}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <div className="col-span-12 md:col-span-6">
                      <label htmlFor="input-phoneNumber" className="form-label">
                        Phone Number
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">{phoneCode}</span>
                        <InputBox
                          type="number"
                          name="phoneNumber"
                          value={values.phoneNumber}
                          onChange={(e) => {
                            setFieldValue('phoneNumber', e.target.value)
                            console.log(errors)
                          }}
                          error={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Status"
                      name="status"
                      options={STATUS_OPTIONS}
                      value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                      onChange={(e) => setFieldValue('status', e.value)}
                      errorText={errors.status && touched.status ? errors.status : null}
                    />
                    {/* <ErrorMessage name="status" component="div" style={{ color: 'red' }} /> */}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Latitude"
                      name="latitude"
                      type="text"
                      placeholder="Latitude"
                      value={values.latitude}
                      onChange={(e) => setFieldValue('latitude', e.target.value)}
                      errorText={errors.latitude && touched.latitude ? errors.latitude : null}
                    />
                    {/* <ErrorMessage name="posId" component="div" style={{ color: 'red' }} /> */}
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Longitude"
                      name="longitude"
                      type="text"
                      placeholder="Longitude"
                      value={values.longitude}
                      onChange={(e) => setFieldValue('longitude', e.target.value)}
                      errorText={errors.longitude && touched.longitude ? errors.longitude : null}
                    />
                    {/* <ErrorMessage name="posId" component="div" style={{ color: 'red' }} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="City"
                      name="address.city"
                      value={values.address.city}
                      onChange={(e) => setFieldValue('address.city', e.target.value)}
                      errorText={errors.address?.city && touched.address?.city ? errors.address.city : null}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Location"
                      name="address.street"
                      value={values.address.street}
                      onChange={(e) => {
                        setFieldValue('address.street', e.target.value)
                        console.log(values)
                      }}
                      errorText={errors.address?.street && touched.address?.street ? errors.address.street : null}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Address"
                      name="address.address"
                      value={values.address.address}
                      onChange={(e) => setFieldValue('address.address', e.target.value)}
                      errorText={errors.address?.address && touched.address?.address ? errors.address.address : null}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      isMulti
                      label="Services"
                      className="form-control"
                      id="input-timezone"
                      name="services"
                      options={SERVICE_OPTIONS}
                      value={values.services}
                      onChange={(e) => {
                        setFieldValue('services', e)
                        console.log(e)
                      }}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Open"
                      type="time"
                      name="open"
                      value={values.open}
                      onChange={(e) => setFieldValue('open', e.target.value)}
                      errorText={errors.open && touched.open ? errors.open : null}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Close"
                      type="time"
                      name="close"
                      value={values.close}
                      onChange={(e) => setFieldValue('close', e.target.value)}
                      errorText={errors.close && touched.close ? errors.close : null}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  {/* Delivery Config Fields */}
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Minimum Cart Value"
                      type="number"
                      name="config.deliveryConfig.minCartValue"
                      value={values?.config?.deliveryConfig?.minCartValue}
                      onChange={(e) => setFieldValue('config.deliveryConfig.minCartValue', e.target.value)}
                      errorText={
                        errors.config?.minCartValue && touched.config?.minCartValue ? errors.config.minCartValue : null
                      }
                    />
                  </div>

                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Minimum Delivery Cost"
                      type="number"
                      name="config.deliveryConfig.minDeliveryCost"
                      value={values?.config?.deliveryConfig?.minDeliveryCost}
                      onChange={(e) => setFieldValue('config.deliveryConfig.minDeliveryCost', e.target.value)}
                      errorText={
                        errors.config?.minDeliveryCost && touched.config?.minDeliveryCost
                          ? errors.config.minDeliveryCost
                          : null
                      }
                    />
                  </div>

                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Minimum Delivery for KM"
                      type="number"
                      name="config.deliveryConfig.minDeliveryForKm"
                      value={values?.config?.deliveryConfig?.minDeliveryForKm}
                      onChange={(e) => setFieldValue('config.deliveryConfig.minDeliveryForKm', e.target.value)}
                      errorText={
                        errors.config?.minDeliveryForKm && touched.config?.minDeliveryForKm
                          ? errors.config.minDeliveryForKm
                          : null
                      }
                    />
                  </div>

                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Cost per KM"
                      type="number"
                      name="config.deliveryConfig.costPerKm"
                      value={values?.config?.deliveryConfig?.costPerKm}
                      onChange={(e) => setFieldValue('config.deliveryConfig.costPerKm', e.target.value)}
                      errorText={errors.config?.costPerKm && touched.config?.costPerKm ? errors.config.costPerKm : null}
                    />
                  </div>

                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Max Delivery Distance"
                      type="number"
                      step="0.01"
                      name="config.deliveryConfig.maxDeliveryDistance"
                      value={values?.config?.deliveryConfig?.maxDeliveryDistance}
                      onChange={(e) => setFieldValue('config.deliveryConfig.maxDeliveryDistance', e.target.value)}
                      errorText={
                        errors.config?.maxDeliveryDistance && touched.config?.maxDeliveryDistance
                          ? errors.config.maxDeliveryDistance
                          : null
                      }
                    />
                  </div>

                  {/* Bite Coin Config Fields */}
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <label htmlFor="product-image-add" className="form-label">
                    Image
                  </label>
                  <CropImage
                    value={values.imageUrl}
                    onChange={(file) => setFieldValue('image', file)}
                    errorText={touched.image && errors.image ? errors.image : null}
                    error={touched.image && errors.image ? errors.image : null}
                    ref={cropImageRef}
                    path={`company/cmp_`}
                    onUploadSuccess={onUploadSuccess}
                    onUploadError={onUploadError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OffCanvasForm>
  )
}

StoreForm.propTypes = {
  id: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.object.isRequired,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    phoneCode: PropTypes.string,
    countryCode: PropTypes.string,
    address: PropTypes.string.isRequired,
    photo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    status: PropTypes.string.isRequired,
    nationality: PropTypes.string.isRequired,
    outletCode: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    deliveryCharge: PropTypes.string.isRequired,
    availableService: PropTypes.string.isRequired,
    serviceType: PropTypes.string,
    availableTenders: PropTypes.string.isRequired,
    tenderType: PropTypes.string,
    location: PropTypes.string,
    currency: PropTypes.string,
    open: PropTypes.string,
    close: PropTypes.string,
    deliveryDistance: PropTypes.string.isRequired,
    pickupDistance: PropTypes.string.isRequired,
    promise: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    services: PropTypes.arrayOf(PropTypes.string),
    storeContacts: PropTypes.shape({
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
    config: PropTypes.shape({
      deliveryConfig: PropTypes.shape({
        minCartValue: PropTypes.number.isRequired,
        minDeliveryCost: PropTypes.number.isRequired,
        minDeliveryForKm: PropTypes.number.isRequired,
        costPerKm: PropTypes.number.isRequired,
        maxDeliveryDistance: PropTypes.number.isRequired,
      }),
    }),
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default StoreForm

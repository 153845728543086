import React from 'react'
import PropTypes from 'prop-types'
const AddProductButton = ({ handleBack, handleNext }) => {
  return (
    <div className="px-6 py-4 border-t border-dashed dark:border-defaultborder/10 sm:flex justify-end">
      <button type="button" className="ti-btn ti-btn-primary !font-medium m-1" onClick={handleBack}>
        Previous
      </button>
      <button type="submit" className="ti-btn ti-btn-success !font-medium m-1" onClick={handleNext}>
        Next
      </button>
    </div>
  )
}

AddProductButton.propTypes = {
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
}
export default AddProductButton

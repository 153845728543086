import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getDocs, orderBy, query, limit, startAfter, endBefore, limitToLast } from 'firebase/firestore'

const getNestedValue = (obj, path) => {
  return path?.split('.').reduce((acc, part) => acc?.[part], obj)
}

export const getPaginatedData = async (dbRef, direction, startAfterDoc, endBeforeDoc, numPerPage = 10, events = {}) => {
  let dataQuery = query(dbRef, orderBy('createdAt'), limit(numPerPage))

  // Update query based on direction and provided documents
  if (direction === 'next' && startAfterDoc) {
    // For next direction, start after the provided document
    dataQuery = query(dataQuery, startAfter(startAfterDoc))
  } else if (direction === 'prev' && endBeforeDoc) {
    // For previous direction, end before the provided document and limit to last
    dataQuery = query(dbRef, orderBy('createdAt'), endBefore(endBeforeDoc), limitToLast(numPerPage))
  }

  const productsSnapshot = await getDocs(dataQuery)
  const products = productsSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }))

  if (events.onPageChange) {
    events.onPageChange()
  }
  return {
    result: products,
    lastDoc: productsSnapshot.docs[productsSnapshot.docs.length - 1],
    firstDoc: productsSnapshot.docs[0],
  }
}

const TableList = forwardRef(({ name, columns, dbRef, tools = {}, pagination = {} }, ref) => {
  const [data, setData] = useState([])
  const [firstDoc, setFirstDoc] = useState(undefined)
  const [lastDoc, setLastDoc] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [end, setEnd] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const pageSize = pagination.pageSize || 10
  const columnsJSX = useMemo(() => [
    <th key="serial-number">Sl.No</th>,
    ...(columns?.map((column) => <th key={column.key}>{column.title}</th>) || []),
  ])

  //data rows
  const rowsJSX = useMemo(
    () =>
      data.map((item, rowIndex) => (
        <tr
          className="product-list invoice-list border border-defaultborder dark:border-defaultborder/10"
          key={item.key || rowIndex}
        >
          <td>{data.indexOf(item) + 1}</td>
          {columns.map((col, columnIndex) => (
            <td key={`${col}-${columnIndex}`} className={col.key === 'actions' ? 'flex gap-2' : ''}>
              {col.render ? col.render(getNestedValue(item, col.dataIndex), item) : getNestedValue(item, col.dataIndex)}
            </td>
          ))}
        </tr>
      )),
    [data, columns]
  )

  useEffect(() => {
    if (dbRef) {
      loadData('next', true)
      setCurrentPage(0)
      setData([])
      setFirstDoc(null)
      setLastDoc(null)
    }
  }, [dbRef])

  const loadData = (direction, reload = false) => {
    const startAfterDoc = direction === 'next' && !reload ? lastDoc : undefined
    const endBeforeDoc = direction === 'prev' && !reload ? firstDoc : undefined

    setLoading(true)
    setEnd(false)
    getPaginatedData(dbRef, direction, startAfterDoc, endBeforeDoc, pageSize, pagination).then((data) => {
      if (data.result.length > 0) {
        setData(data.result)
        setFirstDoc(data.firstDoc)
        setLastDoc(data.lastDoc)
        if (!reload) {
          setCurrentPage((prevPage) => prevPage + (direction === 'next' ? 1 : -1))
        }
      } else {
        setEnd(true)
      }
      setLoading(false)
    })
  }

  // Expose loadData to external components via the ref
  useImperativeHandle(ref, () => ({
    refreshCurrentPage: () => {
      setCurrentPage(0) 
      loadData('next', true)
    },
  }))
  // Handle pagination button clicks
  const handlePreviousClick = () => {
    if (currentPage <= 1 || loading) return
    loadData('prev')
  }

  const handleNextClick = () => {
    if (end || loading) return
    loadData('next')
  }

  //pagination
  const paginationJSX = (
    <nav className="sm:flex items-center justify-between flex-wrap">
      <ul className="ti-pagination mb-0 flex flex-row rounded-sm text-[1rem] !ps-0">
        <li className="page-item">
          <Link
            className="page-link !text-primary !py-[0.375rem] !px-[0.75rem]"
            to="#"
            onClick={() => handlePreviousClick()}
            disabled={currentPage <= 1 || loading}
            style={currentPage <= 1 || loading ? { pointerEvents: 'none' } : null}
          >
            Previous
          </Link>
        </li>
        <Link className="page-link !text-primary !py-[0.375rem] !px-[0.75rem]" to="#">
          {currentPage + 1}
        </Link>
        <li className="page-item">
          <Link
            className="page-link !text-primary !py-[0.375rem] !px-[0.75rem]"
            to="#"
            onClick={() => handleNextClick()}
            disabled={end || loading}
            style={end || loading ? { pointerEvents: 'none' } : null}
          >
            Next
          </Link>
        </li>
      </ul>
    </nav>
  )
  const iconClass = tools.icon || 'ri-add-line'
  return (
    <div className="grid grid-cols-12 gap-6">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-header justify-between">
            <div className="box-title">{name}</div>
            {tools.btnName && (
              <div className="flex">
                <button
                  type="button"
                  onClick={tools.btnClick}
                  className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary"
                >
                  <i className={`${iconClass} font-semibold align-middle me-1`}></i> {tools.btnName}
                </button>
              </div>
            )}
          </div>
          <div className="box-body">
            <div className="table-responsive mb-4">
              <table className="table whitespace-nowrap table-bordered min-w-full">
                <thead>
                  <tr>{columnsJSX}</tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        Loading...
                      </td>
                    </tr>
                  )}
                  {!loading && data.length === 0 && (
                    <tr>
                      <td colSpan={columns.length} className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  )}
                  {!loading && rowsJSX}
                </tbody>
              </table>
            </div>
            {paginationJSX}
          </div>
        </div>
      </div>
    </div>
  )
})
TableList.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  dbRef: PropTypes.object.isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
  }),
  tools: PropTypes.shape({
    btnName: PropTypes.string.isRequired,
    btnIcon: PropTypes.string.isRequired,
    btnClick: PropTypes.func.isRequired,
  }),
}

export default TableList

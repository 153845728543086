import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import CropImage from '@common/src/common/ImageUpload/CropImage'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import { TAX_TYPE_OPTIONS, STATUS_OPTIONS, CURRENCY_OPTIONS } from '@hub/constants/constants'
import { companyAction } from '@common/src/service/cloud/catalog'
import { getCountries, getPaymentOptions } from '../../../shared/src/service/firebase/api'

const validationSchema = Yup.object({
  name: Yup.object({
    en: Yup.string().required('English name is required'),
    ar: Yup.string().required('Arabic name is required'),
  }).required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email address is required'),
  status: Yup.string().required('Status is required'),
  timezone: Yup.string().required('Timezone is required'),
  currency: Yup.string().required('Currency is required'),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, 'Phone number must contain only digits')
    .required('Phone number is required'),
  country: Yup.string().required('country is required'),
  latitude: Yup.number()
    .required('Latitude is required')
    .min(-90, 'Latitude must be between -90 and 90')
    .max(90, 'Latitude must be between -90 and 90'),
  longitude: Yup.number()
    .required('Longitude is required')
    .min(-180, 'Longitude must be between -180 and 180')
    .max(180, 'Longitude must be between -180 and 180'),
  address: Yup.object({
    state: Yup.string().required('State is required'),
    location: Yup.string().required('Location is required'),
    address: Yup.string().required('Address is required'),
  }).required('Address is required'),

  biteCoinConfig: Yup.object().shape({
    minCoin: Yup.number().required('Minimum coin is required').min(1, 'Minimum coin should be at least 1'),
    coinValue: Yup.number().required('Coin value is required').min(1, 'Coin value should be at least 1'),
  }),
  // Tax type
  taxType: Yup.string().required('Tax Type is required'),
  posWebhook: Yup.string().required('Webhook is required'),
  paymentGatewayId: Yup.string().required('Payment gateway is required'),
  // Conditional fields
  vat: Yup.number().when('taxType', {
    is: 'vat',
    then: (schema) => schema.required('VAT Value is required').min(0, 'VAT must be positive'),
    otherwise: (schema) => schema.notRequired(),
  }),

  cgst: Yup.number().when('taxType', {
    is: 'gst',
    then: (schema) => schema.required('CGST is required').min(0, 'CGST must be positive'),
    otherwise: (schema) => schema.notRequired(),
  }),

  sgst: Yup.number().when('taxType', {
    is: 'gst',
    then: (schema) => schema.required('SGST is required').min(0, 'SGST must be positive'),
    otherwise: (schema) => schema.notRequired(),
  }),
})

const CompanyForm = ({ id, data, isEdit, onComplete }) => {
  console.log(data)
  const toast = useToast()
  const cropImageRef = useRef(null)
  const [countryCode, setCountryCode] = useState('+1')
  const [countryOptions, setCountryOptions] = useState([])
  const [paymentOptions, setPaymentOptions] = useState([])
  const [phoneCode, setPhoneCode] = useState('+1')

  const [initialValues, setInitialValues] = useState({
    name: { en: '', ar: '' },
    email: '',
    status: '',
    timezone: '',
    currency: '',
    phoneNumber: '',
    country: '',
    countryCode: '',
    phoneCode: '',
    latitude: '',
    longitude: '',
    address: { state: '', location: '', address: '' },
    taxType: 'vat',
    vat: '',
    sgst: '',
    cgst: '',
    posWebhook: '',
    paymentGatewayId: '',
    biteCoinConfig: {
      minCoin: '',
      coinValue: '',
    },
  })

  useEffect(() => {
    const processCountries = (fetchCountriesList) => {
      return fetchCountriesList.map((doc) => ({
        label: doc.name,
        value: doc,
      }))
    }

    const processPaymentOptions = (getPaymentList) => {
      return getPaymentList.map((doc) => ({
        label: doc.name,
        value: doc.id,
      }))
    }

    const getDefault = (value, defaultValue) => value || defaultValue

    const getLocationCoordinates = (location) => {
      if (!location) return ['', '']
      return location.split(',').map(Number)
    }

    const initializeValues = (data) => {
      if (!data) return

      const [lat, long] = getLocationCoordinates(data.location)
      const { country = {}, config = {} } = data
      const { biteCoinConfig = {} } = config

      setInitialValues({
        name: getDefault(data.name, { en: '', ar: '' }),
        email: getDefault(data.email, ''),
        status: getDefault(data.status, 'ACTIVE'),
        timezone: getDefault(data.timeZone, ''),
        currency: getDefault(data.currencyCode, ''),
        phoneNumber: getDefault(data.phoneNumber, ''),
        country: getDefault(country.country, ''),
        countryCode: getDefault(country.countryCode, ''),
        latitude: lat,
        longitude: long,
        address: getDefault(data.address, { state: '', location: '', address: '' }),
        taxType: getDefault(data.taxType, ''),
        vat: getDefault(data.vat, ''),
        cgst: getDefault(data.cgst, ''),
        sgst: getDefault(data.sgst, ''),
        phoneCode: getDefault(data.phoneCode, ''),
        posWebhook: getDefault(data.posWebhook, ''),
        imageUrl: getDefault(data.photo, ''),
        paymentGatewayId: getDefault(data.paymentGatewayId, ''),
        biteCoinConfig: {
          minCoin: getDefault(biteCoinConfig.minCoin, ''),
          coinValue: getDefault(biteCoinConfig.coinValue, ''),
        },
      })
      setCountryCode(country.countryCode || '')
    }

    const fetchData = async () => {
      try {
        const [fetchCountriesList, getPaymentList] = await Promise.all([getCountries(), getPaymentOptions()])

        setCountryOptions(processCountries(fetchCountriesList))
        setPaymentOptions(processPaymentOptions(getPaymentList))
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchData()
    initializeValues(data)
  }, [data])

  const handleSubmit = async (values, actions) => {
    actions?.setSubmitting(true)
    if (values.image) {
      cropImageRef.current.uploadImage()
    } else {
      await onUploadSuccess({ values, actions })
    }
  }
  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }

  const getErrorMessage = (fieldPath, errors, touched) => {
    const error = fieldPath.split('.').reduce((acc, key) => acc?.[key], errors)
    const isTouched = fieldPath.split('.').reduce((acc, key) => acc?.[key], touched)
    return error && isTouched ? error : null
  }

  const onUploadSuccess = async ({ values, actions, url = '' }) => {
    const formData = {
      name: {
        ar: values.name.ar, // Assuming MultiLanguageInput handles nested input for 'ar'
        en: values.name.en, // Assuming MultiLanguageInput handles nested input for 'en'
      },
      paymentGatewayId: values.paymentGatewayId || '', // Static value, can be dynamic if needed
      location: `${values.latitude},${values.longitude}`, // Combine latitude and longitude
      address: {
        state: values.address.state, // Accessing nested state
        location: values.address.location, // Accessing nested location
        address: values.address.address, // Accessing nested address
      },
      vat: values.taxType === 'vat' ? parseInt(values.vat) : null,
      cgst: values.taxType === 'gst' ? parseInt(values.cgst) : null,
      sgst: values.taxType === 'gst' ? parseInt(values.sgst) : null,
      email: values.email,
      phoneCode: phoneCode, // Assuming countryCode is set correctly
      phoneNumber: parseInt(values.phoneNumber),
      currencyCode: values.currency, // Assuming currency is selected from a dropdown
      taxType: values.taxType, // The selected tax type
      status: values.status, // The status of the form (e.g., ACTIVE)
      timeZone: values.timezone,
      photo: url, // Timezone selected
      country: {
        country: values.country, // Assuming the country object is correctly structured
        countryCode: countryCode, // Assuming the country code is set correctly
      },
      posWebhook: values.posWebhook,
      config: {
        country: {
          country: values.country,
          timezone: values.timezone,
          countryCode: countryCode,
        },
        currency: {
          currency: values.currency,
          currencyCode: values.currency,
        },
        biteCoinConfig: {
          minCoin: values?.biteCoinConfig?.minCoin ? parseInt(values.biteCoinConfig.minCoin, 10) : null,
          coinValue: values?.biteCoinConfig?.coinValue ? parseInt(values.biteCoinConfig.coinValue, 10) : null,
        },
      },
      id: data?.id ? parseInt(data.id) : null,
    }

    try {
      await companyAction(formData, isEdit)
      const toastMessage = isEdit ? 'Company updated successfully!' : 'Company added successfully!'
      toast.success(toastMessage)
      actions?.resetForm()
      onComplete?.()
    } catch (error) {
      toast.error(isEdit ? 'Error updating Company.' : 'Error creating Company.')
      console.error('Form Submission Error:', error)
    }
  }

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText="Cancel"
      id={id}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <div className="grid grid-cols-12 gap-6 ">
          <div className="xl:col-span-12 col-span-12">
            <div className="box">
              <div className="box-body add-products !p-0">
                <div className="grid grid-cols-1 gap-6">
                  <MultiLanguageInput
                    type="input"
                    label="Company Name"
                    id="company-name-add"
                    name="name"
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Email"
                      name="email"
                      type="email"
                      placeholder="email"
                      value={values.email}
                      onChange={(e) => setFieldValue('email', e.target.value)}
                      errorText={getErrorMessage('email', errors, touched)}
                    />
                    {/* <ErrorMessage name="defaultSalesPrice" component="div" style={{ color: 'red' }} /> */}
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Country"
                      name="country"
                      value={countryOptions.find((option) => option?.value?.name === values.country)}
                      options={countryOptions}
                      onChange={(e) => {
                        setPhoneCode(`+${e.value.phoneCode}`)
                        setFieldValue('country', e.value.name)
                        setFieldValue('countryCode', e.value.iso)
                        setFieldValue('currency', e.value.currency)
                        setFieldValue('timezone', e.value.timeZone)
                      }}
                      errorText={getErrorMessage('country', errors, touched)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Status"
                      name="status"
                      options={STATUS_OPTIONS}
                      value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                      onChange={(e) => setFieldValue('status', e.value)}
                      errorText={getErrorMessage('status', errors, touched)}
                    />
                    {/* <ErrorMessage name="status" component="div" style={{ color: 'red' }} /> */}
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Currency"
                      name="currency"
                      options={CURRENCY_OPTIONS}
                      value={CURRENCY_OPTIONS.find((option) => option.value === values.currency)}
                      onChange={(e) => {
                        console.log(errors)
                        setFieldValue('currency', e.value)
                      }}
                      errorText={getErrorMessage('timezone', errors, touched)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="POS Webhook"
                      name="posWebhook"
                      type="text"
                      placeholder="POS Webhook"
                      value={values.posWebhook}
                      onChange={(e) => setFieldValue('posWebhook', e.target.value)}
                      errorText={getErrorMessage('posWebhook', errors, touched)}
                    />
                    {/* <ErrorMessage name="posId" component="div" style={{ color: 'red' }} /> */}
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <SelectBox
                      label="Payment Type"
                      name="paymentGatewayId"
                      options={paymentOptions} // Replace with your tax type options
                      value={paymentOptions.find((option) => option.value === values.paymentGatewayId)}
                      onChange={(e) => {
                        setFieldValue('paymentGatewayId', e.value)
                        console.log(e.value)
                      }}
                      errorText={getErrorMessage('paymentGatewayId', errors, touched)}
                    />
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6">
                  <label htmlFor="input-phoneNumber" className="form-label">
                    Phone Number
                  </label>
                  <div className="input-group">
                    <span className="input-group-text">{phoneCode}</span>
                    <InputBox
                      type="number"
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={(e) => {
                        setFieldValue('phoneNumber', e.target.value)
                        console.log(errors)
                      }}
                      errorText={getErrorMessage('phoneNumber', errors, touched)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Latitude"
                      name="latitude"
                      type="text"
                      placeholder="Latitude"
                      value={values.latitude}
                      onChange={(e) => setFieldValue('latitude', e.target.value)}
                      errorText={getErrorMessage('latitude', errors, touched)}
                    />
                    {/* <ErrorMessage name="posId" component="div" style={{ color: 'red' }} /> */}
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Longitude"
                      name="longitude"
                      type="text"
                      placeholder="Longitude"
                      value={values.longitude}
                      onChange={(e) => setFieldValue('longitude', e.target.value)}
                      errorText={getErrorMessage('longitude', errors, touched)}
                    />
                    {/* <ErrorMessage name="posId" component="div" style={{ color: 'red' }} /> */}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="State"
                      name="address.state"
                      value={values.address.state}
                      onChange={(e) => setFieldValue('address.state', e.target.value)}
                      errorText={getErrorMessage('address.state', errors, touched)}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Location"
                      name="address.location"
                      value={values.address.location}
                      onChange={(e) => setFieldValue('address.location', e.target.value)}
                      errorText={getErrorMessage('address.location', errors, touched)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Address"
                      name="address.address"
                      value={values.address.address}
                      onChange={(e) => setFieldValue('address.address', e.target.value)}
                      errorText={getErrorMessage('address.address', errors, touched)}
                    />
                  </div>
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Timezone"
                      name="address.timezone"
                      value={values.timezone}
                      onChange={(e) => setFieldValue('timezone', e.target.value)}
                      errorText={getErrorMessage('timezone', errors, touched)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-6">
                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Minimum Coin"
                      type="number"
                      name="biteCoinConfig.minCoin"
                      value={values?.biteCoinConfig?.minCoin}
                      onChange={(e) => setFieldValue('biteCoinConfig.minCoin', e.target.value)}
                      errorText={getErrorMessage('biteCoinConfig.minCoin', errors, touched)}
                    />
                  </div>

                  <div className="xl:col-span-1 col-span-12">
                    <InputBox
                      label="Coin Value"
                      type="number"
                      name="biteCoinConfig.coinValue"
                      value={values?.biteCoinConfig?.coinValue}
                      onChange={(e) => setFieldValue('biteCoinConfig.coinValue', e.target.value)}
                      errorText={
                        errors.biteCoinConfig?.coinValue && touched.biteCoinConfig?.coinValue
                          ? errors.biteCoinConfig.coinValue
                          : null
                      }
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-6">
                  <div className="xl:col-span-12 col-span-12">
                    <div className="box">
                      <div className="box-body add-products !p-0">
                        {/* Other fields above */}

                        {/* Tax Type Selection */}
                        <div className="grid grid-cols-2 gap-6">
                          <div className="xl:col-span-1 col-span-12">
                            <SelectBox
                              label="Tax Type"
                              name="taxType"
                              options={TAX_TYPE_OPTIONS} // Replace with your tax type options
                              value={TAX_TYPE_OPTIONS.find((option) => option.value === values.taxType)}
                              onChange={(e) => setFieldValue('taxType', e.value)}
                              error={errors.taxType && touched.taxType ? errors.taxType : null}
                            />
                          </div>

                          {/* Conditional Fields */}
                          {values.taxType === 'vat' ? (
                            <div className="xl:col-span-1 col-span-12">
                              <InputBox
                                label="VAT Value (%)"
                                name="vat"
                                type="number"
                                placeholder="Enter VAT Value"
                                value={values.vat}
                                onChange={(e) => setFieldValue('vat', e.target.value)}
                                error={errors.vat && touched.vat ? errors.vat : null}
                              />
                            </div>
                          ) : (
                            <>
                              <div className="xl:col-span-1 col-span-12">
                                <InputBox
                                  label="CGST (%)"
                                  name="cgst"
                                  type="number"
                                  placeholder="Enter CGST"
                                  value={values.cgst}
                                  onChange={(e) => setFieldValue('cgst', e.target.value)}
                                  error={errors.cgst && touched.cgst ? errors.cgst : null}
                                />
                              </div>
                              <div className="xl:col-span-1 col-span-12">
                                <InputBox
                                  label="SGST (%)"
                                  name="sgst"
                                  type="number"
                                  placeholder="Enter SGST"
                                  value={values.sgst}
                                  onChange={(e) => setFieldValue('sgst', e.target.value)}
                                  error={errors.sgst && touched.sgst ? errors.sgst : null}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        {/* Other fields below */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <label htmlFor="product-image-add" className="form-label">
                    Image
                  </label>
                  <CropImage
                    value={values.imageUrl}
                    onChange={(file) => setFieldValue('image', file)}
                    errorText={touched.image && errors.image ? errors.image : null}
                    error={touched.image && errors.image ? errors.image : null}
                    ref={cropImageRef}
                    path={`company/cmp_`}
                    onUploadSuccess={onUploadSuccess}
                    onUploadError={onUploadError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </OffCanvasForm>
  )
}

CompanyForm.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.shape({
      en: PropTypes.string.isRequired,
      ar: PropTypes.string.isRequired,
    }),
    email: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    timezone: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    phoneCode: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    location: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
    paymentGatewayId: PropTypes.string,
    posWebhook: PropTypes.string,
    taxType: PropTypes.string.taxType,
    address: PropTypes.shape({
      state: PropTypes.string,
      location: PropTypes.string,
      address: PropTypes.string,
    }),
    vat: PropTypes.string.vat,
    cgst: PropTypes.string.cgst,
    sgst: PropTypes.string.sgst,
    photo: PropTypes.string.vat,
    config: PropTypes.shape({
      country: PropTypes.shape({
        country: PropTypes.string.isRequired,
        timezone: PropTypes.string.isRequired,
        countryCode: PropTypes.string.isRequired,
      }).isRequired,
      currency: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        currencyCode: PropTypes.string.isRequired,
      }).isRequired,
      biteCoinConfig: PropTypes.shape({
        minCoin: PropTypes.number, // Optional, can be `null` if not provided
        coinValue: PropTypes.number, // Optional, can be `null` if not provided
      }).isRequired,
    }).isRequired,
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default CompanyForm

import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import SelectBox from '@common/src/common/selectbox/selectbox'
import InputBox from '@common/src/common/inputbox/inputbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'

const validationSchema = Yup.object({
  modifierGroup: Yup.object().test('has-value', 'Modifier Group is required', obj => Object.keys(obj).length > 0),
  quantity: Yup.string().required('Quantity is required'),
})

const ModifierGroupAdd = ({ id, options, data, isEdit, setTableData, tableData }) => {
  const [initialValues, setInitialValues] = useState({
    modifierGroup: {},
    quantity: '',
  })

  useEffect(() => {
    if (data) {
      setInitialValues({
        modifierGroup: data.modifierGroup || {},
        quantity: data.quantity || '',
      })
    }
  }, [data])

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    const formData = {
      ...values,
    };
  
    if (isEdit) {
      // Update the existing modifier
      const updatedTableData = tableData.map((item) =>
        item.modifierGroup.id === formData.modifierGroup.id ? formData : item
      );
      setTableData(updatedTableData);
    } else {
      // Add a new modifier
      setTableData([...tableData, formData]);
    }
  
    // Reset the form to initial values
    actions.resetForm({
      values: {
        modifierGroup: {},
        quantity: '',
      },
    });
    actions.setSubmitting(false);
  };
  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <div className="grid grid-cols-1 gap-6">
          {console.log("errors", errors)}
          <SelectBox
            label="Modifier Group"
            name="modifierGroup"
            options={options}
            value={options.find((option) => option.value === values.modifierGroup)}
            onChange={(e) => setFieldValue('modifierGroup', { id: e.value, value: e.label })}
            errorText={errors.modifierGroup && touched.modifierGroup ? errors.modifierGroup : null}
          />
          <InputBox
            label="Quantity"
            name="quantity"
            type="text"
            placeholder="Enter Quantity"
            value={values.quantity}
            onChange={(e) => setFieldValue('quantity', e.target.value)}
            errorText={errors.quantity && touched.quantity ? errors.quantity : null}
          />
        </div>
      )}
    </OffCanvasForm>
  )
}

ModifierGroupAdd.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.shape({
    modifierGroup: PropTypes.string,
    quantity: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  setTableData: PropTypes.func,
  tableData: PropTypes.array,
}

export default ModifierGroupAdd
import { useState, useEffect } from 'react'
import PageHeader from '@common/src/pageheader/pageheader'
import Pagination from '@hub/components/common/Pagination/pagination'
import { GET_ALL_PRODUCTS } from '@common/src/lib/graphql/query/product'
import { useQuery } from '@apollo/client'
import AvatarImage from '@hub/components/common/ImageHolders/avatarImage'
import SearchBox from '@common/src/common/inputbox/searchBox'

const Productlist = () => {
  const [rows, setRows] = useState([])
  const [searchQuery, setSearchQuery] = useState('');
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    totalPages: 0
  });

  const [info, setInfo] = useState('No Products found.');
  const { data, refetch, loading } = useQuery(GET_ALL_PRODUCTS, {
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      search: searchQuery || undefined,
    },
  });

  useEffect(() => {
    if (data) {
      setRows(data.productListQuery.rows);
      setPagination((prevPagination) => ({
        ...prevPagination,
        totalPages: data.productListQuery.totalPages,
      }))
    } else {
      setRows([]);
      searchQuery && setInfo('No results found for your search.')
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        limit: pagination.limit,
        offset: pagination.offset,
      },
    });
  }, [pagination.offset]);

  return (
    <>
      <PageHeader currentpage="Menu" activepage="Menu Management" mainpage="Menu" />
      <div className="grid grid-cols-12 gap-6">
        <div className="xl:col-span-12 col-span-12">
          <div className="box">
            <div className="box-header flex items-center justify-between">
              <div className="box-title">Menu Items</div>
              <SearchBox placeHolder="Search by product name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
              <div className="flex items-center">
                <button type="button" className="ti-btn !py-1 !px-2 !text-[0.75rem] !text-white !font-medium bg-primary ms-2">
                  <i className="ri-add-line font-semibold align-middle me-1"></i>Add Item
                </button>
              </div>
            </div>
            <div className="box-body">
              <div className="table-responsive mb-4">
                <table className="table whitespace-nowrap table-bordered min-w-full">
                  <thead>
                    <tr>
                      <th scope="col" className="text-start">
                        Sl.No
                      </th>
                      <th scope="col" className="text-start">
                        Item
                      </th>
                      <th scope="col" className="text-start">
                        Type
                      </th>
                      <th scope="col" className="text-start">
                        Price
                      </th>
                      <th scope="col" className="text-start">
                        Company
                      </th>
                      <th scope="col" className="text-start">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={8} className="text-center">
                          <div className="d-flex justify-content-center">
                            <div className="spinner-border">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {rows.length > 0 ? (
                          rows.map((item) => (
                            <tr className="product-list" key={item.id}>
                              <td>{(pagination.currentPage - 1) * pagination.limit + rows.indexOf(item) + 1}</td>
                              <td>
                                <div className="flex items-center">
                                  <div className="me-2">
                                    <AvatarImage src={item?.image?.[0]} />
                                  </div>
                                  <div className="font-semibold">{item?.name?.en}</div>
                                </div>
                              </td>
                              <td>
                                <span className="badge bg-info text-white">{item?.type?.replace(/_/g, ' ')}</span>
                              </td>
                              <td>{item?.price}</td>
                              <td>{item?.company?.name?.en}</td>
                              <td>
                                <div className="space-x-1 rtl:space-x-reverse">
                                  <button
                                    // onClick={() => onEditClick(item)}
                                    className="ti-btn ti-btn-primary ti-btn-icon ti-btn-sm"
                                  >
                                    <i className="ri-pencil-line"></i>
                                  </button>
                                  <button
                                    // onClick={() => onEditClick(item)}
                                    className="ti-btn ti-btn-info ti-btn-icon ti-btn-sm"
                                  >
                                    <i className="ri-arrow-right-up-fill"></i>
                                  </button>
                                </div>
                              </td>

                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              <div className="alert alert-info">
                                {info}
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination pagination={pagination} setPagination={setPagination} rows={rows} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Productlist



// RowImage component - renders an image with a spinner while it's loading
import PropTypes from 'prop-types';
const RowImage = ({ src, width = "250px" }) => {

    return (
        <div className="relative">
            <img width={width} src={src} className="hidden"
                onLoad={
                    (e) => {
                        // remove hidden class when image is loaded and remove spinner
                        e.target.classList.remove('hidden');
                        const parent = e.target.parentElement;
                        const imgSpin = parent.querySelector('.img-spin');
                        if (imgSpin) parent.removeChild(imgSpin);
                    }
                }
                alt="" />
            <div className="absolute inset-0 flex items-center justify-center img-spin">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
            </div>
        </div>
    )
}
RowImage.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.string,
};
export default RowImage;

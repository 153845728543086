import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CropImage from '@common/src/common/ImageUpload/CropImage'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'
import { IMG_UPLOAD, STATUS_OPTIONS } from '@hub/constants/constants'
import { useToast } from '@common/src/common/Toast/ToastProvider'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage'
import { triggerBannerActions } from '@hub/service/firebase/company'
import { getStorage, ref as fbRef, uploadBytes, getDownloadURL } from 'firebase/storage'




const BannerForm = ({ id, data, isEdit, onComplete }) => {
    const toast = useToast()
    const webImageRef = useRef(null)
    const appImageRef = useRef(null)
    const { selectedCompany } = useSelector((state) => state.auth)
    const [isImage, setIsImage] = useState({ web: isEdit, app: isEdit })
    const [cropFiles, setCropFiles] = useState({ web: '', app: '' })
    const [initialValues, setInitialValues] = useState({})
    const { user } = useSelector((state) => state.auth)
    const getInitialData = async () => {
        setInitialValues({
            description: data?.description || '',
            status: data?.status ? { value: data?.status, label: data?.status } : { value: 'ACTIVE', label: 'ACTIVE' },
            web: data?.web || '',
            app: data?.app || '',
            sortOrder: data?.sortOrder || 0,
            startDate: data?.startDate ? new Date(parseFloat(data?.startDate?.seconds * 1000)).toISOString().slice(0, 16) : '',
            endDate: data?.endDate ? new Date(parseFloat(data?.endDate?.seconds * 1000)).toISOString().slice(0, 16) : '',
            link: data?.link || '',
        })
    }
    useEffect(() => {
        getInitialData()
    }, [])

    const validationSchema = Yup.object({
        startDate: Yup.date().required('Start date is required'),
        endDate: Yup.date().required('End date is required'),
        sortOrder: Yup.number().required('Sort Order is required').min(0),
        web: !isImage.web && Yup.mixed()
            .test('web', 'Web app banner is required', (value) => value?.name !== undefined)
            .test('web', 'Format should be jpeg or png or jpg', (value) =>
                !value ||
                IMG_UPLOAD.BANNER.WEB.FORMATS.includes(value?.type?.split('/')[1])
            )
            .test('web', `Size should be maximum ${IMG_UPLOAD.BANNER?.WEB?.MAX_SIZE}`, (value) =>
                !value ||
                value.size <= 1024 * 1024
            )
            .required('Web app banner is required'),
        app: !isImage.app && Yup.mixed()
            .test('app', 'App banner is required', (value) => value !== undefined)
            .test('app', 'Format should be jpeg or png or jpg', (value) =>
                !value ||
                IMG_UPLOAD.BANNER.APP.FORMATS.includes(value?.type?.split('/')[1])
            )
            .test('app', `Size should be maximum ${IMG_UPLOAD.BANNER?.APP?.MAX_SIZE}`, (value) =>
                !value ||
                value.size <= 1024 * 1024
            )
            .required('App banner is required'),
    })

    const handleImageUpload = async (file, type) => {
        // Initialize Firebase storage
        const storage = getStorage()
        // Generate a unique string for the file name
        const randomString = Date.now()
        // Construct the file path
        let path = `company/${selectedCompany?.value}/banner/${type}_bnr_`
        const storageRef = fbRef(storage, `${path}_${randomString}.${file.path.split('.').pop()}`)

        // Upload the file to Firebase storage
        const uploadTask = uploadBytes(storageRef, cropFiles[type])

        try {
            // Wait for the upload to complete and get the download URL
            await uploadTask
            const url = await getDownloadURL(storageRef)
            return url
        } catch (error) {
            // Log any errors that occur during upload
            console.log('error', error)
            return null
        }
    }
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true); // mark form submission as in progress

        // Prepare form data
        const formData = isEdit
            ? {
                id: data.id,
                ...(values.status.value !== initialValues.status.value && { status: values.status.value }),
                ...(values.link !== initialValues.link && { link: values.link }),
                ...(values.startDate !== initialValues.startDate && { startDate: values.startDate }),
                ...(values.endDate !== initialValues.endDate && { endDate: values.endDate }),
                ...(values.sortOrder !== initialValues.sortOrder && { sortOrder: values.sortOrder }),
                companyId: String(selectedCompany.value),
                updatedBy: user.uid,
            } : {
                ...values,
                status: values.status.value,
                companyId: String(selectedCompany.value),
                createdBy: user.uid
            };
            console.log("values", formData)
        // Handle web image upload if present
        if (webImageRef.current) {
            const webUrl = await handleImageUpload(values.web, 'web');
            formData.web = webUrl;
        }

        // Handle app image upload if present
        if (appImageRef.current) {
            const appUrl = await handleImageUpload(values.app, 'app');
            formData.app = appUrl;
        }

        try {
            // Trigger banner actions (add/update)
            await triggerBannerActions(formData, isEdit);
            toast.success(isEdit ? 'Banner updated successfully!' : 'Banner added successfully!');
            resetForm(); // reset the form after successful submission
            setSubmitting(false); // mark form submission as complete
            onComplete?.(); // invoke onComplete callback if available
        } catch (error) {
            // Handle errors during submission
            toast.error(isEdit ? 'Error updating banner.' : 'Error creating banner.');
            setSubmitting(false); // mark form submission as complete
        }
    };

    return (
        <OffCanvasForm
            enableReinitialization
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            okText={isEdit ? 'Update' : 'Add'}
            closeText={isEdit ? 'Close' : 'Cancel'}
            id={id}
            autoClose={false}
        >
            {({ setFieldValue, values, errors, touched }) => {
                return (
                    <div className="grid grid-cols-12 gap-6">
                        <div className="xl:col-span-12 col-span-12">
                            <div className="box">
                                <div className="box-body add-products !p-0">
                                    <div className="grid grid-cols-1 gap-6">
                                        <label className="form-label" htmlFor="webImage">
                                            Web Image
                                        </label>
                                        {isImage.web ?
                                            <PreviewImage
                                                src={values.web}
                                                isRemovable={true}
                                                onRemove={() => {
                                                    setFieldValue('web', undefined);
                                                    setIsImage({ ...isImage, web: false })
                                                }}
                                            />
                                            : <CropImage
                                                value={values.web}
                                                onChange={(file) => setFieldValue('web', file)}
                                                setCropFile={(file) => setCropFiles((prev) => ({ ...prev, web: file }))}
                                                errorText={touched.web && errors.web ? errors.web : null}
                                                ref={webImageRef}
                                                path={`company/${selectedCompany.value}/banner/web_bnr_`}
                                                minCrop={IMG_UPLOAD.BANNER.WEB.MIN_RESOLUTION}
                                            />
                                        }
                                        <label className="form-label" htmlFor="appImage">
                                            App Image
                                        </label>
                                        {isImage.app ?
                                            <PreviewImage
                                                src={values.app}
                                                isRemovable={true}
                                                onRemove={() => {
                                                    setFieldValue('app', undefined);
                                                    setIsImage({ ...isImage, app: false })
                                                }}
                                            />
                                            : <CropImage
                                                value={values.app}
                                                onChange={(file) => setFieldValue('app', file)}
                                                setCropFile={(file) => setCropFiles((prev) => ({ ...prev, app: file }))}
                                                errorText={touched?.app && errors.app ? errors.app : null}
                                                ref={appImageRef}
                                                path={`company/${selectedCompany.value}/banner/app_bnr_`}
                                                minCrop={IMG_UPLOAD.BANNER.APP.MIN_RESOLUTION}
                                            />

                                        }

                                        <div className="grid grid-cols-1 gap-6">
                                            <InputBox
                                                type="text"
                                                name="link"
                                                label="Banner Link"
                                                placeholder="Enter Banner Link"
                                                value={values.link}
                                                onChange={(e) => setFieldValue('link', e.target.value)}
                                                id="link"
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-6">
                                            <InputBox
                                                type="text"
                                                id="description"
                                                name="description"
                                                label="Description"
                                                placeholder="Enter Description"
                                                value={values.description}
                                                onChange={(e) => setFieldValue('description', e.target.value)}
                                            />
                                        </div>

                                        <div className="grid grid-cols-1 gap-6">
                                            <InputBox
                                                type="number"
                                                name="sortOrder"
                                                label="Sort Order"
                                                placeholder="Enter Sort Order"
                                                value={values.sortOrder}
                                                onChange={(e) => setFieldValue('sortOrder', e.target.value)}
                                                min={0}
                                                id="sortOrder"
                                            />
                                        </div>
                                        <div className="grid grid-cols-1 gap-6">
                                            <div className="grid grid-cols-2 gap-6">
                                                <InputBox
                                                    type="datetime-local"
                                                    name="startDate"
                                                    label="Start Date"
                                                    placeholder="Enter Start Date"
                                                    value={values.startDate}
                                                    onChange={(e) => setFieldValue('startDate', e.target.value)}
                                                    min={!isEdit && new Date().toISOString().split('T')[0] + 'T00:00'}
                                                    errorText={touched.startDate && errors.startDate ? errors.startDate : null}
                                                    id="startDate"
                                                />
                                                <InputBox
                                                    type="datetime-local"
                                                    name="endDate"
                                                    label="End Date"
                                                    placeholder="Enter End Date"
                                                    value={values.endDate}
                                                    onChange={(e) => setFieldValue('endDate', e.target.value)}
                                                    min={values.startDate}
                                                    errorText={touched.endDate && errors.endDate ? errors.endDate : null}
                                                    id="endDate"
                                                />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1 gap-6">
                                            <SelectBox
                                                name="status"
                                                label="Status"
                                                value={values.status}
                                                onChange={(e) => { setFieldValue('status', e) }}
                                                id="status"
                                                options={STATUS_OPTIONS}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        </OffCanvasForm>
    )
}
BannerForm.propTypes = {
    data: PropTypes.exact({
        id: PropTypes.string.isRequired,
        description: PropTypes.string,
        link: PropTypes.string,
        status: PropTypes.string.isRequired,
        web: PropTypes.string.isRequired,
        app: PropTypes.string.isRequired,
        sortOrder: PropTypes.number.isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        endDate: PropTypes.instanceOf(Date).isRequired,
    }),
    isEdit: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
    id: PropTypes.string,
}


export default BannerForm

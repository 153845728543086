// roles.js
import { getDocs, collection } from 'firebase/firestore'
import { db } from '../../../../src/firebase/firebaseapi'
import { COLLECTIONS } from '../../constants/constants'

export const getCompany = async () => {
  try {
    const companiesCollection = collection(db, COLLECTIONS.COMPANIES)
    const querySnapshot = await getDocs(companiesCollection)
    const companies = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    return companies
  } catch (error) {
    console.error('Error fetching company data:', error)
  }
}

import { useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import CropImage from '@common/src/common/ImageUpload/CropImage'
import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import InputBox from '@common/src/common/inputbox/inputbox'
import SelectBox from '@common/src/common/selectbox/selectbox'
import OffCanvasForm from '@common/src/common/offcanvas/offCanvasForm'

import { STATUS_OPTIONS } from '@hub/constants/constants'
import { useToast } from '@common/src/common/Toast/ToastProvider'

import { addNotifications, updateNotificationData } from '../../../service/firebase/notification'
import PreviewImage from '@hub/components/common/ImageHolders/previewImage'



const NotificationForm = ({ id, data, isEdit, onComplete }) => {

  const validationSchema = Yup.object({
  
    status: Yup.string().required('Status is required'),
    image: Yup.mixed()
      .required('Please upload an image')
      .test('fileFormat', 'Please upload an image in .jpg, .jpeg, or .png format', (value) => {
        if(typeof value === 'string') return true
        const validFormats = ['image/jpeg', 'image/png', 'image/jpg']
        return value && validFormats.includes(value.type)
      })
      .test('fileSize', 'Max file size is restricted to 4 MB', (value) => {
        if(typeof value === 'string') return true
        const maxSize = 4 * 1024 * 1024 // 4 MB
        return value && value.size <= maxSize
      }),
    name: Yup.object()
      .shape({
        en: Yup.string().required('English name is required'),
        ar: Yup.string().required('Arabic name is required'),
      })
      .required('Name is required'),
    description: Yup.object()
      .shape({
        en: Yup.string().required('English description is required'),
        ar: Yup.string().required('Arabic description is required'),
      })
      .required('Description is required'),
    launchUrl: Yup.string().url('Please enter a valid URL').required('Redirection URL is required'),
  })

  const toast = useToast()
  const cropImageRef = useRef(null)
  const { selectedCompany } = useSelector((state) => state.auth)
  const [initialValues, setInitialValues] = useState({
    name: {},
    description: {},
    image: '',
    status: '',
    launchUrl: '',
  })

  const getSingleCategoryData = async () => {
    setInitialValues({
      name: data?.name || {},
      description: data?.description || {},
      status: data?.status || '',
      image: data?.image || '',
      launchUrl: data?.launchUrl || ''
    })
  }

  useEffect(() => {
    getSingleCategoryData()
  }, [])

  const onUploadError = (error) => {
    toast.error('Error uploading image')
    onComplete?.()
  }

  const onUploadSuccess = async ({ values, actions, url }) => {
    const formData = {
      ...values,
      image: url,
      companyId: selectedCompany?.value,
      ...(data?.id && { id: data?.id }),
    };

    // Determine whether to create or update based on `isEdit`
    if (isEdit) {
      await updateNotification(formData, actions);
    } else {
      await handleSubmit(formData, actions);
    }
  };

  const handleSubmit = async (values, actions) => {
    
    actions?.setSubmitting(true);
    if (cropImageRef.current) {
      cropImageRef.current.uploadImage()
    }
  
    const formData = {
      name: {
        en: values?.name.en,
        ar: values?.name.ar,
      },
      description: {
        en: values?.description.en,
        ar: values?.description.ar,
      },
      image: values?.image,
      status: values?.status,
      launchUrl: values?.launchUrl,
    };

    await addNotifications(formData, selectedCompany?.value);
    actions?.resetForm();
    actions?.setSubmitting(false);
    HSOverlay.close(`#${id}`);
    onComplete?.();
  };

  const updateNotification = async (values, actions) => {
    
    try {
      actions?.setSubmitting(true);
      if (cropImageRef.current) {
        cropImageRef.current.uploadImage()
      }
      await updateNotificationData(data.id, values, selectedCompany?.value);
      toast.success(isEdit ? 'Notification updated successfully!' : 'Push notification created successfully!');
      actions?.resetForm();
      actions?.setSubmitting(false);
      HSOverlay.close(`#${id}`);
      onComplete?.();
    } catch (error) {
      toast.error(isEdit ? 'Error updating Category.' : 'Error creating Category.');
      console.error('Form Submission Error:', error);
    }
  }

  return (
    <OffCanvasForm
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={isEdit ? updateNotification : handleSubmit}
      okText={isEdit ? 'Update' : 'Add'}
      closeText={isEdit ? 'Close' : 'Cancel'}
      id={id}
      autoClose={false}
    >
      {({ setFieldValue, values, errors, touched }) => {
        return (
          <div className="grid grid-cols-12 gap-6">
            {/* Category Name and Starting Price */}
            <div className="xl:col-span-12 col-span-12">
              <div className="box">
                <div className="box-body add-products !p-0">
                  <div className="grid grid-cols-3 gap-6">
                    <MultiLanguageInput
                      type="input"
                      label="Title"
                      id="title"
                      name="name"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                    <MultiLanguageInput
                      type="textarea"
                      label="Description"
                      id="category-description-text"
                      name="description"
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  </div>
                  <div className="grid grid-cols-3 gap-6">
                    <InputBox
                      label="Redirection Url"
                      name="launchUrl"
                      type="text"
                      placeholder="Redirection Url"
                      value={values.launchUrl}
                      onChange={(e) => setFieldValue('launchUrl', e.target.value)}
                      errorText={errors.launchUrl && touched.launchUrl ? errors.launchUrl : null}
                    />
                    <SelectBox
                      label="Status"
                      name="status"
                      options={STATUS_OPTIONS}
                      value={STATUS_OPTIONS.find((option) => option.value === values.status)}
                      defaultValue={STATUS_OPTIONS[0]}
                      onChange={(e) => setFieldValue('status', e.value)}
                      errorText={errors.status && touched.status ? errors.status : null}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-6">
                    <CropImage
                      value={values?.image}
                      onChange={(file) => {
                        setFieldValue('image', file)
                      }}
                      errorText={touched.image && errors.image ? errors.image : null}
                      ref={cropImageRef}
                      path={`company/${selectedCompany?.value}/notifications/not_`}
                      onUploadSuccess={onUploadSuccess}
                      onUploadError={onUploadError} 
                      setFieldValue={setFieldValue}     
                    />
                  </div>
                  {isEdit && (
                     <div className='preview-img'>
                     <PreviewImage src={values?.image} isRemovable ={true}  onRemove={() => setFieldValue('image', '')}/>
                     </div>
                    
                  )}
                 
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </OffCanvasForm>
  )
}
NotificationForm.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    modifiers: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    status: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    launchUrl: PropTypes.string.isRequired
  
  }),
  isEdit: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default NotificationForm

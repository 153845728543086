import React, { useState } from 'react'
import BasicDetials from './steps/BasicDetails'
import ImageUplaod from './steps/ImageUpload'
import Pricing from './steps/Pricing'
import Availability from './steps/Availability'
//import LocationDetails from './steps/Location'
import Modifiers from './steps/modifierGroups/Modifiers'
import Configuration from './steps/Configuration'
import PageHeader from '@common/src/pageheader/pageheader'
import ChefPicks from './steps/ChefPicks'
const Stepper = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [productData, setProductData] = useState({})
  console.log('productData', productData)
  //const steps = ['Basic Details', 'ImageUplaod', 'Pricing', 'Modifiers','chefPicks', 'Configuration','Location','Availability']
  const steps = ['Basic Details', 'ImageUplaod', 'Pricing', 'Modifiers','chefPicks','Availability','Configuration']
  const handleNext = () => {
    console.log('activeStep', activeStep)
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <>
      <PageHeader currentpage="Add Product" activepage="Add Products" mainpage="Products" />
      <div className="flex justify-center space-x-4 my-4 bg-[#f3f4f6] dark:bg-[#171923]">
        <nav className="sm:flex sm:space-x-2 sm:rtl:space-x-reverse" aria-label="Tabs">
          {steps.map((step, index) => (
            <button
              type="button"
              key={step}
              className={
                index === activeStep
                  ? 'w-full sm:w-auto hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap text-defaulttextcolor hover:text-primary dark:text-[#8c9097] dark:text-white/50 active'
                  : 'w-full sm:w-auto hs-tab-active:font-semibold hs-tab-active:border-primary hs-tab-active:text-primary py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap text-defaulttextcolor hover:text-primary dark:text-[#8c9097] dark:text-white/50'
              }
              onClick={() => setActiveStep(index)}
              id={`tabs-item-${index}`}
              data-hs-tab={`#tabs-${index}`}
              aria-controls={`tabs-${index}`}
            >
              {step}
            </button>
          ))}
        </nav>
      </div>
      {activeStep === 0 && <BasicDetials handleNext={handleNext} setProductData={setProductData} productData={productData}/> }
      {activeStep === 1 && <ImageUplaod handleNext={handleNext} handleBack={handleBack} setProductData={setProductData} productData={productData} />}
      {activeStep === 2 && <Pricing handleNext={handleNext} handleBack={handleBack} setProductData={setProductData} productData={productData} />}     
      {activeStep === 3 && <Modifiers handleNext={handleNext} setProductData={setProductData} productData={productData} />}
      {activeStep === 4 && <ChefPicks handleNext={handleNext}  setProductData={setProductData} productData={productData}/>}
      {activeStep === 5 && <Availability handleNext={handleNext}  setProductData={setProductData} productData={productData} />}
      {activeStep === 6 && <Configuration handleNext={handleNext} setProductData={setProductData} productData={productData} />}
      {/* {activeStep === 6 && <LocationDetails handleNext={handleNext} setProductData={setProductData} productData={productData} />}  */}
    </>
  )
}

export default Stepper

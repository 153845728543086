import MultiLanguageInput from '@common/src/common/MultiLanguageInput/MultiLanguageInput'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import SelectBox from '@common/src/common/selectbox/selectbox'
import { Productoptions1 } from '../../addproducts/addproductdata'
import AddProductButton from '../../../components/common/buttons/AddProductButton'
import PropTypes from 'prop-types'
import { useState } from 'react'
import InputBox from '@common/src/common/inputbox/inputbox'

const BasicDetials = ({ handleNext, setProductData, productData }) => {
  console.log('productData>>>>>>>', productData)
  const [initialValues] = useState({
    name: productData?.name || {},
    description: productData?.description || {},
    type: productData?.type || '',
    category: productData?.category || '',
    posId: productData?.posId || '', // Added posId
  })
  
  const handleSubmit = async (values) => {
    console.log('values>>>>', values)
    setProductData(values)
    //const response = await createProduct(values)
    //console.log("response", response)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.object()
      .shape({
        en: Yup.string().required('English name is required'),
        ar: Yup.string().required('Arabic name is required'),
      }),
    description: Yup.object()
      .shape({
        en: Yup.string().required('English description is required'),
        ar: Yup.string().required('Arabic description is required'),
      }),
    type: Yup.string().required('Food Type is required'),
    category: Yup.string().required('Category in English is required'),
    posId: Yup.string().required('POS ID is required'), // Added validation for posId
  })

  return (
    <div className="grid grid-cols-12 gap-6 ">
      <div className="xl:col-span-12 col-span-12">
        <div className="box">
          <div className="box-body add-products !p-0">
            <div className="p-6">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  console.log("values", values)
                  handleSubmit(values)
                  handleNext()
                  setSubmitting(false)
                }}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form>
                    {console.log(errors, touched, values)}

                    <div className="grid grid-cols-2 gap-6">
                      {/* Product Name */}
                      <div className="xl:col-span-1 col-span-12">
                        <MultiLanguageInput
                          type="input"
                          label="Product Name"
                          id="product-name-add"
                          name='name'
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      </div>
                      {/* Category */}
                      <div className="xl:col-span-1 col-span-12">
                        <SelectBox
                          id="product-category-add"
                          label="Category"
                          options={Productoptions1}
                          name="category"
                          onChange={(e) => setFieldValue('category', e.value)}
                        />
                        <ErrorMessage name="category" component="div" style={{ color: 'red' }} />
                      </div>
                      {/* Description */}
                      <div className="xl:col-span-1 col-span-12">
                        <MultiLanguageInput
                          type="textarea"
                          label="Description"
                          id="product-description-add"
                          name='description'
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      </div>
                      {/* Food Type Radio Button */}
                      <div className="xl:col-span-1 col-span-12">
                        <label className="form-label">Food Type</label>
                        <div className="form-check form-check-md flex items-center">
                          <Field type="radio" name="type" value="veg" className="form-check-input" id="radio-lg" />
                          <label className="form-check-label" htmlFor="radio-lg">
                            Veg
                          </label>
                        </div>
                        <div className="form-check form-check-md flex items-center mt-3">
                          <Field
                            type="radio"
                            name="type"
                            value="non-veg"
                            className="form-check-input"
                            id="radio-md"
                          />
                          <label className="form-check-label" htmlFor="radio-md">
                            Non-Veg
                          </label>
                        </div>
                        <ErrorMessage name="type" component="div" style={{ color: 'red' }} />
                      </div>
                      {/* POS ID */}
                      <div className="xl:col-span-1 col-span-12">
                        <label htmlFor="posId" className="form-label">POS ID</label>
                        <InputBox
                          placeholder="POS ID"
                          type="text"
                          id="posId"
                          name="posId"
                          value={values.posId}
                          onChange={(e) => setFieldValue('posId', e.target.value)}
                        />
                        <ErrorMessage name="posId" component="div" style={{ color: 'red' }} />
                      </div>
                    </div>
                    <AddProductButton />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

BasicDetials.propTypes = {
  handleNext: PropTypes.func.isRequired,
  setProductData: PropTypes.func.isRequired,
  productData: PropTypes.object, // Changed to object to match the structure
}

export default BasicDetials
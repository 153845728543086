import { gql } from '@apollo/client';

export const GET_ALL_COMPANIES = gql`
  query companyListQuery($search: String, $limit: Int, $offset: Int) {
    companyQuery(search: $search, limit: $limit, offset: $offset) {
      limit
      offset
      totalPages
      rows {
        id
        name {
          ar
          en
        }
        email
        photo
        phoneCode
        phoneNumber
        address{
            state
            address
            location
        }
      }
    }
  }
`;

export const Productoptions1 = [
  { value: 'Category ', label: 'Category' },
  { value: 'Clothing ', label: 'Clothing' },
  { value: 'Footwear ', label: 'Footwear' },
  { value: 'Accesories ', label: 'Accesories' },
  { value: 'Grooming ', label: 'Grooming' },
  { value: 'Ethnic & Festive ', label: 'Ethnic & Festive' },
  { value: 'Jewellery ', label: 'Jewellery' },
  { value: 'Toys & Babycare ', label: 'Toys & Babycare' },
  { value: 'Festive Gifts ', label: 'Festive Gifts' },
  { value: 'Kitchen ', label: 'Kitchen' },
  { value: 'Dining ', label: 'Dining' },
  { value: 'Home Decors ', label: 'Home Decors' },
  { value: 'Stationery ', label: 'Stationery' },
]
export const Productoptions2 = [
  { value: 'Select', label: 'Select' },
  { value: 'All', label: 'All' },
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Others', label: 'Others' },
]
export const Productoptions3 = [
  { value: '', label: 'Select' },
  { value: 'Extra Small', label: 'Extra Small' },
  { value: 'Small', label: 'Small' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Large', label: 'Large' },
  { value: 'Extra Large', label: 'Extra Large' },
]
export const Productoptions4 = [
  { value: '', label: 'Select' },
  { value: 'Armani', label: 'Armani' },
  { value: 'Lacoste', label: 'Lacoste' },
  { value: 'Puma', label: 'Puma' },
  { value: 'Spykar', label: 'Spykar' },
  { value: 'Mufti', label: 'Mufti' },
  { value: 'Home Town', label: 'Home Town' },
  { value: 'Arrabi', label: 'Arrabi' },
]
export const Productoptions5 = [
  { value: 'White', label: 'White' },
  { value: 'Black', label: 'Black' },
  { value: 'Red', label: 'Red' },
  { value: 'Orange', label: 'Orange' },
  { value: 'Yellow', label: 'Yellow' },
  { value: 'Green', label: 'Green' },
  { value: 'Blue', label: 'Blue' },
  { value: 'Pink', label: 'Pink' },
  { value: 'Purple', label: 'Purple' },
]
export const Productoptions6 = [
  { value: 'Select', label: 'Select' },
  { value: 'Published', label: 'Published' },
  { value: 'Scheduled', label: 'Scheduled' },
]
export const Productoptions7 = [
  { value: 'Relaxed', label: 'Relaxed' },
  { value: 'Solid', label: 'Solid' },
  { value: 'Washed', label: 'Washed' },
  { value: 'Plain', label: 'Plain' },
]
export const Productoptions8 = [
  { value: 'Select', label: 'Select' },
  { value: 'In Stock', label: 'In Stock' },
  { value: 'Out Of Stock', label: 'Out Of Stock' },
]
